import { call, put } from 'redux-saga/effects';
import { deleteOfAdmin } from '../api';
import { DELETE_QUOTE_ERROR, DELETE_QUOTE_SUCCESS } from '../../consts/allUsersTypes';
import { ACTIVATE_ALERT } from '../../consts/utilsTypes';

export function* deleteQuoteAllUsersRequestSaga({ payload }) {
	try {
		const { id, msgSuccess } = payload;
		yield call(deleteOfAdmin, '/assisted-purchase/' + id); // response {} obj para del back para el back
		yield put({ type: DELETE_QUOTE_SUCCESS, response: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: msgSuccess, type: 'success' } });
		yield put({ type: DELETE_QUOTE_SUCCESS }); // reset
	} catch (error) {
		yield put({ type: DELETE_QUOTE_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
		yield put({ type: DELETE_QUOTE_SUCCESS }); // reset
	}
}
