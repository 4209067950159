import { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Header from './Header';
import Sidebar from './Sidebar';

const MainLayout = props => {
	// Handle left drawer
	const [mobileOpen, setMobileOpen] = useState(false);
	const [openSubMenu, setOpenSubMenu] = useState(false);
	const auth = props.user;

	const handleDrawerToggle = useCallback(() => {
		// Only toggle if screen size is xs
		if (window.matchMedia('(max-width: 599px)').matches) {
			setMobileOpen(!mobileOpen);
			setOpenSubMenu(false);
		}
	}, [mobileOpen]);

	return (
		<div>
			{/* Header */}
			<Header drawerOpen={mobileOpen} drawerToggle={handleDrawerToggle} {...props} />

			{/* drawer */}
			{auth && (
				<Sidebar
					drawerOpen={mobileOpen}
					drawerToggle={handleDrawerToggle}
					drawerSetting={setMobileOpen}
					drawerSubOpen={openSubMenu}
					drawerSubToggle={setOpenSubMenu}
					{...props}
				/>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		user: state.authReducer.token,
	};
};

MainLayout.propTypes = {
	user: PropTypes.string,
};

export default connect(mapStateToProps)(MainLayout);
