import * as typesClient from '../../consts/clientTypes';
import * as types from '../../consts/actionTypes';

export const postQuoteRequest = payload => ({
	type: typesClient.POST_QUOTE_REQUEST,
	payload,
});

export const getOrdersRequest = payload => ({
	type: typesClient.GET_ORDERS_REQUEST,
	payload,
});

export const postOrderRequest = payload => ({
	type: typesClient.POST_ORDER_REQUEST,
	payload,
});

export const getQuoteDropshippingRequest = payload => ({
	type: typesClient.GET_QUOTE_DROPSHIPPING_REQUEST,
	payload,
});

export const postQuoteDropshippingRequest = payload => ({
	type: typesClient.POST_QUOTE_DROPSHIPPING_REQUEST,
	payload,
});

export const patchQuotedDropshippingRequest = payload => ({
	type: typesClient.PATCH_QUOTED_DROPSHIPPING_REQUEST,
	payload,
});

export const postQuoteSuccess = () => ({
	type: types.POST_QUOTE_SUCCESS,
});

export const postAuthorizedNameRequest = payload => ({
	type: types.POST_AUTHORIZED_NAME_REQUEST,
	payload,
});

export const postAuthorizedNameSuccess = () => ({
	type: types.POST_AUTHORIZED_NAME_SUCCESS,
});

export const putOrderDescriptionRequest = payload => ({
	type: types.PUT_ORDER_DESCRIPTION_REQUEST,
	payload,
});

export const getAuthorizedNameRequest = payload => ({
	type: types.GET_AUTHORIZED_NAME_REQUEST,
	payload,
});

export const getMyPackagesRequest = payload => ({
	type: types.GET_MY_PACKAGES_REQUEST,
	payload,
});

export const getPayPackageRequest = payload => ({
	type: types.GET_PAY_PACKAGE_REQUEST,
	payload,
});

export const clearClient = payload => ({
	type: types.RESET_CLIENT,
	payload,
});

export const postGenerateBillOfSaleRequest = payload => ({
	type: types.POST_GENERATE_BILL_OF_SALE_REQUEST,
	payload,
});

// export const postGenerateBillOfSaleSuccess = () => ({
// 	type: types.POST_GENERATE_BILL_OF_SALE_SUCCESS
// });

export const getGenerateBillOfSaleRequest = payload => ({
	type: types.GET_GENERATE_BILL_OF_SALE_REQUEST,
	payload,
});

// export const getGenerateBillOfSaleSuccess = () => ({
// 	type: types.GET_GENERATE_BILL_OF_SALE_SUCCESS
// });


export const postAuthorizationRequest = payload => ({
	type: typesClient.POST_AUTHORIZATION_REQUEST,
	payload,
});


export const patchUpdateOrderRequest = payload => ({
	type: types.PUT_UPDATE_ORDER_REQUEST,
	payload,
});
