export const GET_ALL_CHARGES_REQUEST = 'GET_ALL_CHARGES_REQUEST';
export const GET_ALL_CHARGES_ERROR = 'GET_ALL_CHARGES_ERROR';
export const GET_ALL_CHARGES_SUCCESS = 'PGET_ALL_CHARGES_SUCCESS';

export const POST_CHARGE_REQUEST = 'POST_CHARGE_REQUEST';
export const POST_CHARGE_ERROR = 'POST_CHARGE_ERROR';
export const POST_CHARGE_SUCCESS = 'POST_CHARGE_SUCCESS';

export const PUT_CHARGE_REQUEST = 'PUT_CHARGE_REQUEST';
export const PUT_CHARGE_ERROR = 'PUT_CHARGE_ERROR';
export const PUT_CHARGE_SUCCESS = 'PUT_CHARGE_SUCCESS';

export const DELETE_CHARGE_REQUEST = 'DELETE_CHARGE_REQUEST';
export const DELETE_CHARGE_ERROR = 'DELETE_CHARGE_ERROR';
export const DELETE_CHARGE_SUCCESS = 'DELETE_CHARGE_SUCCESS';