import { put, call } from 'redux-saga/effects';
import {
	GET_ROLES_ERROR,
	GET_ROLES_SUCCESS,
	GET_ROLE_ERROR,
	GET_ROLE_SUCCESS,
	POST_RESET_PASSWORD_CONFIRM_ERROR,
	POST_RESET_PASSWORD_CONFIRM_SUCCESS,
	POST_RESET_PASSWORD_ERROR,
	POST_RESET_PASSWORD_SUCCESS,
	POST_SIGNIN_ERROR,
	POST_SIGNIN_SUCCESS,
	POST_SIGNUP_ERROR,
	POST_SIGNUP_SUCCESS,
	PUT_CHANGE_PASSWORD_ERROR,
	PUT_CHANGE_PASSWORD_SUCCESS,
	PUT_PROFILE_ERROR,
	PUT_PROFILE_SUCCESS,
} from '../../consts/actionTypes';
import { getRole, postSign, putProfile } from '../api';
import { ACTIVATE_ALERT } from '../../consts/utilsTypes';

export function* postSigninRequestSaga({ payload }) {
	try {
		const authData = yield call(postSign, '/auth/signin', payload);
		localStorage.setItem('snap', JSON.stringify({ token: authData.accessToken }));
		yield put({ type: POST_SIGNIN_SUCCESS, payload: authData });
	} catch (error) {
		yield put({ type: POST_SIGNIN_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

export function* postSignupRequestSaga({ payload }) {
	try {
		const authData = yield call(postSign, '/auth/signup', payload);
		yield put({ type: POST_SIGNUP_SUCCESS, authData });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { message: 'Creación de cuenta exitosa, ahora ya posee una cuenta.', type: 'success' },
		});
		yield put({ type: POST_SIGNUP_SUCCESS }); // resetSignupRequest
	} catch (error) {
		yield put({ type: POST_SIGNUP_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
		yield put({ type: POST_SIGNUP_SUCCESS }); // resetSignupRequest
	}
}

export function* getRolesRequestSaga() {
	try {
		const roles = yield call(getRole, '/role', null);
		yield put({ type: GET_ROLES_SUCCESS, roles });
	} catch (error) {
		yield put({ type: GET_ROLES_ERROR, error });
	}
}

export function* getRoleRequestSaga() {
	try {
		const roleData = yield call(getRole, '/auth/me', null);
		yield put({ type: GET_ROLE_SUCCESS, roleData });
	} catch (error) {
		localStorage.removeItem('snap');
		yield put({ type: GET_ROLE_ERROR, error });
	}
}

export function* postResetPasswordRequestSaga({ payload }) {
	try {
		const resetData = yield call(postSign, '/auth/reset-password', payload);
		yield put({ type: POST_RESET_PASSWORD_SUCCESS, resetData });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { message: 'En caso de existir la cuenta, revisar su correo electrónico.', type: 'success' },
		});
		yield put({ type: POST_RESET_PASSWORD_SUCCESS }); // resetSignupRequest
	} catch (error) {
		yield put({ type: POST_RESET_PASSWORD_ERROR, error });
		yield put({
			type: ACTIVATE_ALERT,
			payload: { message: 'En caso de existir la cuenta, revisar su correo electrónico.', type: 'success' },
		});
		yield put({ type: POST_RESET_PASSWORD_SUCCESS }); // resetSignupRequest
	}
}

export function* postResetPasswordConfirmRequestSaga({ payload }) {
	try {
		yield call(postSign, '/auth/reset-password/confirm', payload);
		yield put({ type: POST_RESET_PASSWORD_CONFIRM_SUCCESS, resetConfirm: 1 });
	} catch (error) {
		yield put({ type: POST_RESET_PASSWORD_CONFIRM_ERROR, error });
	}
}

export function* putProfileRequestSaga({ payload }) {
	try {
		const profile = yield call(putProfile, '/auth/update-profile', payload);
		yield put({ type: PUT_PROFILE_SUCCESS, profile });
	} catch (error) {
		yield put({ type: PUT_PROFILE_ERROR, error });
	}
}

export function* putChangePasswordRequestSaga({ payload }) {
	try {
		yield call(putProfile, '/auth/change-password', payload);
		yield put({ type: PUT_CHANGE_PASSWORD_SUCCESS, changePass: 1 });
	} catch (error) {
		yield put({ type: PUT_CHANGE_PASSWORD_ERROR, error });
	}
}
