import axios from 'axios';
// eslint-disable-next-line no-undef
const { VITE_APP_API } = import.meta.env;

const httpInstance = (history = null, logoutuser = null, extraHeaders = {}) => {
	const headers = { 'Content-Type': 'application/json', ...extraHeaders };
	const userIsLogin = localStorage.getItem('snap')

	if (userIsLogin) {
		headers.Authorization = `Bearer ${JSON.parse(userIsLogin).token}`;
	}

	if (localStorage.getItem('Lang')) {
		headers['x-custom-lang'] = localStorage.getItem('Lang');
	}

	// Se crea instancia http con valores default
	const httpInstance = axios.create({
		baseURL: VITE_APP_API,
		headers,
	});

	// Interceptor para enviar datos recibidos y checar errores
	httpInstance.interceptors.response.use(
		config => {
			const conf = config.config;
			const headers = config.headers;

			if (
				conf.responseType === 'blob' && 
				headers['content-type'] && 
				headers['content-disposition']
			) {
				const blob = new Blob([config.data], { type: headers['content-type'] });
				const url = window.URL.createObjectURL(blob);
				const a = document.createElement('a');
				
				a.href = url;
				a.download = getFilename(headers['content-disposition']);
				a.click();
				
				window.URL.revokeObjectURL(url);
			}
			
			return config;
		},
		error => {
			if (!error.response) {
				return new Promise((resolve, reject) => {
					reject(error);
				});
			}
			
			// if (error.response.status === 403 || error.response.status === 401) {
			if (userIsLogin && error.response.status === 401) {
				console.log('No autorizado');
				// logoutuser(localStorage.getItem('snap'));
				window.location.replace('/');
				localStorage.removeItem('snap');
			}

			return new Promise((resolve, reject) => {
				reject(error);
			});
		}
	);

	return httpInstance;
};

function getFilename(disposition) {
	if (disposition && disposition.indexOf('attachment') !== -1) {
        const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = filenameRegex.exec(disposition);
        
		if (matches != null && matches[1]) { 
          
			return matches[1].replace(/['"]/g, '');
        }
    }

	return null;
}

export default httpInstance;
