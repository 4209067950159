import * as types from '../../consts/utilsTypes';

export const activateAlert = payload => ({
	type: types.ACTIVATE_ALERT,
	payload,
});

export const deactivateAlert = () => ({
	type: types.DEACTIVATE_ALERT,
});
