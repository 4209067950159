import * as types from '../../../consts/admin/chargesTypes';

const initiaState = {
	charges: null,
};

function chargesReducer(state = initiaState, action) {
	switch (action.type) {
		case types.GET_ALL_CHARGES_REQUEST:
			return { ...state, isChargesLoading: true, errorCharges: null };
		case types.GET_ALL_CHARGES_ERROR:
			return { ...state, isChargesLoading: false, errorCharges: action.error.response.data.message?.toString() };
		case types.GET_ALL_CHARGES_SUCCESS:
			if (action.charges) {
				return { ...state, isChargesLoading: false, charges: action.charges, errorCharges: null };
			}
			return { ...state, isChargesLoading: false, charges: null, errorCharges: null };

		case types.POST_CHARGE_REQUEST:
        case types.PUT_CHARGE_REQUEST:
        case types.DELETE_CHARGE_REQUEST:
			return { ...state, isChargesLoading: true, errorCharges: null };
		case types.POST_CHARGE_ERROR:
        case types.PUT_CHARGE_ERROR:
        case types.DELETE_CHARGE_ERROR:
			return { ...state, isChargesLoading: false, errorCharges: action.error.response.data.message?.toString() };
		case types.POST_CHARGE_SUCCESS:
        case types.PUT_CHARGE_SUCCESS:
        case types.DELETE_CHARGE_SUCCESS:
			if (action.response) {
				return { ...state, isChargesLoading: false, errorCharges: null };
			}
			return { ...state, isChargesLoading: false, errorCharges: null };
		default:
			return state;
	}
}

export default chargesReducer;
