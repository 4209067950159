import { put, call } from 'redux-saga/effects';
import {
	GET_MY_PACKAGES_ERROR,
	GET_MY_PACKAGES_SUCCESS,
	GET_PAY_PACKAGE_ERROR,
	GET_PAY_PACKAGE_SUCCESS,
	PUT_ORDER_DESCRIPTION_SUCCESS,
	PUT_ORDER_DESCRIPTION_ERROR,
	POST_AUTHORIZED_NAME_SUCCESS,
	POST_AUTHORIZED_NAME_ERROR,
	GET_AUTHORIZED_NAME_SUCCESS,
	GET_AUTHORIZED_NAME_ERROR,
	GET_GENERATE_BILL_OF_SALE_SUCCESS,
	GET_GENERATE_BILL_OF_SALE_ERROR,
	POST_GENERATE_BILL_OF_SALE_SUCCESS,
	POST_GENERATE_BILL_OF_SALE_ERROR,
	PUT_UPDATE_ORDER_SUCCESS,
	PUT_UPDATE_ORDER_ERROR,
} from '../../consts/actionTypes';
import { postOfClient, getOfClient, putOfClient, patchOfClient } from '../api/client';
import { ACTIVATE_ALERT } from '../../consts/utilsTypes';
import {
	GET_ORDERS_ERROR,
	GET_ORDERS_SUCCESS,
	GET_QUOTE_DROPSHIPPING_ERROR,
	GET_QUOTE_DROPSHIPPING_SUCCESS,
	PATCH_QUOTED_DROPSHIPPING_ERROR,
	PATCH_QUOTED_DROPSHIPPING_SUCCESS,
	POST_AUTHORIZATION_ERROR,
	POST_AUTHORIZATION_SUCCESS,
	POST_ORDER_ERROR,
	POST_ORDER_SUCCESS,
	POST_QUOTE_DROPSHIPPING_ERROR,
	POST_QUOTE_DROPSHIPPING_SUCCESS,
	POST_QUOTE_ERROR,
	POST_QUOTE_SUCCESS,
} from '../../consts/clientTypes';

const {
	VITE_APP_INWAREHOUSEUIO,
	VITE_APP_INWAREHOUSEMIA,
	VITE_APP_SENDED,
	VITE_APP_PENDINGOFPAY,
	VITE_APP_INREVIEW,
	VITE_APP_REVIEWED,
	VITE_APP_ARRIVED,
	VITE_APP_PAID,
	VITE_APP_RECEIVED_AND_DELIVERED,
	VITE_APP_DROPSHIPPING_QUOTING,
} = import.meta.env;

export function* postQuoteRequestSaga({ payload }) {
	try {
		yield call(postOfClient, '/quotation/request', payload.body);
		yield put({ type: POST_QUOTE_SUCCESS, clientData: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: payload.msgSuccess, type: 'success' } });
		yield put({ type: POST_GENERATE_BILL_OF_SALE_SUCCESS }); // reset postQuoteSuccess
	} catch (error) {
		yield put({ type: POST_QUOTE_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

export function* getOrdersRequestSaga({ payload }) {
	try {
		const orders = yield call(getOfClient, '/order/client?status=' + VITE_APP_INREVIEW + '' + payload.url);
		yield put({ type: GET_ORDERS_SUCCESS, orders });
	} catch (error) {
		yield put({ type: GET_ORDERS_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

export function* postOrderRequestSaga({ payload }) {
	try {
		yield call(postOfClient, '/order/create', payload, { 'Content-Type': 'multipart/form-data' });
		yield put({ type: POST_ORDER_SUCCESS, ok: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: 'Orden creada exitosamente.', type: 'success' } });
		yield put({ type: POST_ORDER_SUCCESS }); // postOrderSuccess
	} catch (error) {
		yield put({ type: POST_ORDER_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
		yield put({ type: POST_ORDER_SUCCESS }); // postOrderSuccess
	}
}

export function* getQuoteDropshippingRequestSaga({ payload }) {
	try {
		const dropshipping = yield call(
			getOfClient,
			'/assisted-purchase?status' + VITE_APP_DROPSHIPPING_QUOTING + '' + payload
		);
		yield put({ type: GET_QUOTE_DROPSHIPPING_SUCCESS, dropshipping });
	} catch (error) {
		yield put({ type: GET_QUOTE_DROPSHIPPING_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

export function* postQuoteDropshippingRequestSaga({ payload }) {
	try {
		yield call(postOfClient, '/assisted-purchase', payload.body); // response ''
		yield put({ type: POST_QUOTE_DROPSHIPPING_SUCCESS, created: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: payload.msgSuccess, type: 'success' } });
		yield put({ type: POST_QUOTE_DROPSHIPPING_SUCCESS }); // reset
	} catch (error) {
		yield put({ type: POST_QUOTE_DROPSHIPPING_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
		yield put({ type: POST_QUOTE_DROPSHIPPING_SUCCESS }); // reset
	}
}

export function* patchQuotedDropshippingRequestSaga({ payload }) {
	try {
		yield call(patchOfClient, '/assisted-purchase/' + payload.id, payload.body); // response {} obj que usa el mismo back
		yield put({ type: PATCH_QUOTED_DROPSHIPPING_SUCCESS, updated: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: payload.msgSuccess, type: 'success' } });
		yield put({ type: PATCH_QUOTED_DROPSHIPPING_SUCCESS }); // reset
	} catch (error) {
		yield put({ type: PATCH_QUOTED_DROPSHIPPING_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
		yield put({ type: PATCH_QUOTED_DROPSHIPPING_SUCCESS }); // reset
	}
}

export function* putOrderDescriptionRequestSaga({ payload }) {
	try {
		yield call(putOfClient, '/order/description', payload);
		yield put({ type: PUT_ORDER_DESCRIPTION_SUCCESS, putOrder: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: 'Descripción actualizada con exito.', type: 'success' } });
	} catch (error) {
		yield put({ type: PUT_ORDER_DESCRIPTION_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

export function* getMyPackagesRequestSaga({ payload }) {
	try {
		const orders = yield call(
			getOfClient,
			'/order/client?status=' +
				VITE_APP_REVIEWED +
				'&status=' +
				VITE_APP_INWAREHOUSEMIA +
				'&status=' +
				VITE_APP_SENDED +
				'&status=' +
				VITE_APP_ARRIVED +
				'' +
				payload.url
		);
		yield put({ type: GET_MY_PACKAGES_SUCCESS, orders });
	} catch (error) {
		yield put({ type: GET_MY_PACKAGES_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

export function* getPayPackageRequestSaga({ payload }) {
	try {
		const orders = yield call(
			getOfClient,
			'/order/client?status=' +
				VITE_APP_PENDINGOFPAY +
				'&status=' +
				VITE_APP_INWAREHOUSEUIO +
				'&status=' +
				VITE_APP_PAID +
				'&status=' +
				VITE_APP_RECEIVED_AND_DELIVERED +
				'' +
				payload.url
		);
		yield put({ type: GET_PAY_PACKAGE_SUCCESS, orders });
	} catch (error) {
		yield put({ type: GET_PAY_PACKAGE_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

export function* postAuthorizedNameRequestSaga({ payload }) {
	try {
		yield call(postOfClient, '/user/authorized-names', payload);
		yield put({ type: POST_AUTHORIZED_NAME_SUCCESS, okAuthorizedName: 1 });
	} catch (error) {
		yield put({ type: POST_AUTHORIZED_NAME_ERROR, error });
	}
}

export function* getAuthorizedNameRequestSaga() {
	try {
		const authorizedName = yield call(getOfClient, '/user/authorized-names');
		yield put({ type: GET_AUTHORIZED_NAME_SUCCESS, authorizedName });
	} catch (error) {
		yield put({ type: GET_AUTHORIZED_NAME_ERROR, error });
	}
}

export function* postGenerateBillOfSaleSaga({ payload }) {
	try {
		const billOfSale = yield call(postOfClient, '/sale-order', payload.data);
		yield put({ type: POST_GENERATE_BILL_OF_SALE_SUCCESS, billOfSale });
		yield put({ type: ACTIVATE_ALERT, payload: { message: payload.msgSuccess, type: 'success' } });
		yield put({ type: POST_GENERATE_BILL_OF_SALE_SUCCESS }); // resetPostGenerateBillOfSale
	} catch (error) {
		yield put({ type: POST_GENERATE_BILL_OF_SALE_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
		yield put({ type: POST_GENERATE_BILL_OF_SALE_SUCCESS }); // resetPostGenerateBillOfSale
	}
}

export function* getGenerateBillOfSaleSaga({ payload }) {
	try {
		const billOfSale = yield call(getOfClient, '/sale-order/order/' + payload.id + '/pdf', { responseType: 'blob' });
		yield put({ type: GET_GENERATE_BILL_OF_SALE_SUCCESS, billOfSale });
		yield put({ type: ACTIVATE_ALERT, payload: { message: payload.msgSuccess, type: 'success' } });
		yield put({ type: GET_GENERATE_BILL_OF_SALE_SUCCESS }); // resetGetGenerateBillOfSale
	} catch (error) {
		yield put({ type: GET_GENERATE_BILL_OF_SALE_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
		yield put({ type: GET_GENERATE_BILL_OF_SALE_SUCCESS }); // resetGetGenerateBillOfSale
	}
}

export function* postAuthorizationRequestSaga({ payload }) {
	try {
		yield call(postOfClient, '/order/is-authorized-to-bundle', payload);
		yield put({ type: POST_AUTHORIZATION_SUCCESS, okAuthorization: 1 });
	} catch (error) {
		yield put({ type: POST_AUTHORIZATION_ERROR, error });
	}
}

export function* patchUpdateOrderRequestSaga({ payload }) {
	//AQUI
	try {
		yield call(postOfClient, '/order/update-incomplete/' + payload.id, payload.body); // response {} obj que usa el mismo back
		yield put({ type: PUT_UPDATE_ORDER_SUCCESS, updated: 1 });
		yield put({ type: ACTIVATE_ALERT, payload: { message: payload.msgSuccess, type: 'success' } });
		yield put({ type: PUT_UPDATE_ORDER_SUCCESS }); // reset
	} catch (error) {
		yield put({ type: PUT_UPDATE_ORDER_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
		yield put({ type: PUT_UPDATE_ORDER_SUCCESS }); // reset
	}
}
