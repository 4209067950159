import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import SummarizeIcon from '@mui/icons-material/Summarize';

const client = {
	id: 'client-items',
	title: 'Client',
	type: 'client',
	children: [
		{
			id: 'new-order-item',
			title: 'yourShopping',
			type: 'item',
			url: '/',
			subItems: false,
			icon: ShoppingBasketIcon,
			breadcrumbs: false,
		},
		{
			id: 'assisted-purchase-item',
			title: 'yourAssistedPurchases',
			type: 'item',
			url: '/tuscompraasistidas',
			subItems: false,
			icon: SummarizeIcon,
			// external: true,
			// target: true
		},
		{
			id: 'my-packages-item',
			title: 'yourOrders',
			type: 'item',
			url: '/tuspedidos',
			subItems: false,
			icon: LocalMallIcon,
			// external: true,
			// target: true
		},
		{
			id: 'amountToBePaid-item',
			title: 'yourPayments',
			type: 'item',
			url: '/tuspagos',
			subItems: false,
			icon: MonetizationOnIcon,
			// external: true,
			// target: true
		},
		{
			id: 'quote-item',
			title: 'quote',
			type: 'item',
			url: '/cotizar',
			subItems: false,
			icon: RequestQuoteIcon,
			// external: true,
			// target: true
		},
	],
};

export default client;
