// ================== Auth ==============================
export const RESET_USER = 'RESET_USER';
export const UPDATE_OPTION_SIDEBAR_REQUEST = 'UPDATE_OPTION_SIDEBAR_REQUEST';
// export const UPDATE_OPTION_SIDEBAR_SUCCESS = 'UPDATE_OPTION_SIDEBAR_SUCCESS';

export const POST_SIGNIN_REQUEST = 'POST_SIGNIN_REQUEST';
export const POST_SIGNIN_SUCCESS = 'POST_SIGNIN_SUCCESS';
export const POST_SIGNIN_ERROR = 'POST_SIGNIN_ERROR';

export const POST_SIGNUP_REQUEST = 'POST_SIGNUP_REQUEST';
export const POST_SIGNUP_SUCCESS = 'POST_SIGNUP_SUCCESS';
export const POST_SIGNUP_ERROR = 'POST_SIGNUP_ERROR';

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR';

export const GET_ROLE_REQUEST = 'GET_ROLE_REQUEST';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_ERROR = 'GET_ROLE_ERROR';

export const POST_RESET_PASSWORD_REQUEST = 'POST_RESET_PASSWORD_REQUEST';
export const POST_RESET_PASSWORD_SUCCESS = 'POST_RESET_PASSWORD_SUCCESS';
export const POST_RESET_PASSWORD_ERROR = 'POST_RESET_PASSWORD_ERROR';

export const PUT_PROFILE_REQUEST = 'PUT_PROFILE_REQUEST';
export const PUT_PROFILE_SUCCESS = 'PUT_PROFILE_SUCCESS';
export const PUT_PROFILE_ERROR = 'PUT_PROFILE_ERROR';

export const PUT_CHANGE_PASSWORD_REQUEST = 'PUT_CHANGE_PASSWORD_REQUEST';
export const PUT_CHANGE_PASSWORD_SUCCESS = 'PUT_CHANGE_PASSWORD_SUCCESS';
export const PUT_CHANGE_PASSWORD_ERROR = 'PUT_CHANGE_PASSWORD_ERROR';

export const POST_RESET_PASSWORD_CONFIRM_REQUEST = 'POST_RESET_PASSWORD_CONFIRM_REQUEST';
export const POST_RESET_PASSWORD_CONFIRM_SUCCESS = 'POST_RESET_PASSWORD_CONFIRM_SUCCESS';
export const POST_RESET_PASSWORD_CONFIRM_ERROR = 'POST_RESET_PASSWORD_CONFIRM_ERROR';

// ================== Client ==============================
export const RESET_CLIENT = 'RESET_CLIENT';

export const PUT_ORDER_DESCRIPTION_REQUEST = 'PUT_ORDER_DESCRIPTION_REQUEST';
export const PUT_ORDER_DESCRIPTION_SUCCESS = 'PUT_ORDER_DESCRIPTION_SUCCESS';
export const PUT_ORDER_DESCRIPTION_ERROR = 'PUT_ORDER_DESCRIPTION_ERROR';

export const GET_MY_PACKAGES_REQUEST = 'GET_MY_PACKAGES_REQUEST';
export const GET_MY_PACKAGES_SUCCESS = 'GET_MY_PACKAGES_SUCCESS';
export const GET_MY_PACKAGES_ERROR = 'GET_MY_PACKAGES_ERROR';

export const GET_PAY_PACKAGE_REQUEST = 'GET_PAY_PACKAGE_REQUEST';
export const GET_PAY_PACKAGE_SUCCESS = 'GET_PAY_PACKAGE_SUCCESS';
export const GET_PAY_PACKAGE_ERROR = 'GET_PAY_PACKAGE_ERROR';

export const GET_AUTHORIZED_NAME_REQUEST = 'GET_AUTHORIZED_NAME_REQUEST';
export const GET_AUTHORIZED_NAME_SUCCESS = 'GET_AUTHORIZED_NAME_SUCCESS';
export const GET_AUTHORIZED_NAME_ERROR = 'GET_AUTHORIZED_NAME_ERROR';

export const POST_AUTHORIZED_NAME_REQUEST = 'POST_AUTHORIZED_NAME_REQUEST';
export const POST_AUTHORIZED_NAME_SUCCESS = 'POST_AUTHORIZED_NAME_SUCCESS';
export const POST_AUTHORIZED_NAME_ERROR = 'POST_AUTHORIZED_NAME_ERROR';

// Bill Of Sale
export const GET_GENERATE_BILL_OF_SALE_REQUEST = 'GET_GENERATE_BILL_OF_SALE_REQUEST';
export const GET_GENERATE_BILL_OF_SALE_SUCCESS = 'GET_GENERATE_BILL_OF_SALE_SUCCESS';
export const GET_GENERATE_BILL_OF_SALE_ERROR = 'GET_GENERATE_BILL_OF_SALE_ERROR';

export const POST_GENERATE_BILL_OF_SALE_REQUEST = 'POST_GENERATE_BILL_OF_SALE_REQUEST';
export const POST_GENERATE_BILL_OF_SALE_SUCCESS = 'POST_GENERATE_BILL_OF_SALE_SUCCESS';
export const POST_GENERATE_BILL_OF_SALE_ERROR = 'POST_GENERATE_BILL_OF_SALE_ERROR';

// ================== Admin ==============================
export const RESET_ADMIN = 'RESET_ADMIN';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_ERROR = 'GET_USERS_ERROR';

export const PUT_USERS_REQUEST = 'PUT_USERS_REQUEST';
export const PUT_USERS_SUCCESS = 'PUT_USERS_SUCCESS';
export const PUT_USERS_ERROR = 'PUT_USERS_ERROR';

export const GET_CLIENTS_REQUEST = 'GET_CLIENTS_REQUEST';
export const GET_CLIENTS_SUCCESS = 'GET_CLIENTS_SUCCESS';
export const GET_CLIENTS_ERROR = 'GET_CLIENTS_ERROR';

export const GET_CLIENTS_ORDERS_REQUEST = 'GET_CLIENTS_ORDERS_REQUEST';
export const GET_CLIENTS_ORDERS_SUCCESS = 'GET_CLIENTS_ORDERS_SUCCESS';
export const GET_CLIENTS_ORDERS_ERROR = 'GET_CLIENTS_ORDERS_ERROR';

export const PUT_CLIENT_PAYMENT_REQUEST = 'PUT_CLIENT_PAYMENT_REQUEST';
export const PUT_CLIENT_PAYMENT_SUCCESS = 'PUT_CLIENT_PAYMENT_SUCCESS';
export const PUT_CLIENT_PAYMENT_ERROR = 'PUT_CLIENT_PAYMENT_ERROR';

export const PUT_ASSOCIATE_PAYMENT_REQUEST = 'PUT_ASSOCIATE_PAYMENT_REQUEST';
export const PUT_ASSOCIATE_PAYMENT_SUCCESS = 'PUT_ASSOCIATE_PAYMENT_SUCCESS';
export const PUT_ASSOCIATE_PAYMENT_ERROR = 'PUT_ASSOCIATE_PAYMENT_ERROR';

export const GET_ASSOCIATED_REQUEST = 'GET_ASSOCIATED_REQUEST';
export const GET_ASSOCIATED_SUCCESS = 'GET_ASSOCIATED_SUCCESS';
export const GET_ASSOCIATED_ERROR = 'GET_ASSOCIATED_ERROR';

export const GET_CLIENTSWITHOUTASSOCIATED_REQUEST = 'GET_CLIENTSWITHOUTASSOCIATED_REQUEST';
export const GET_CLIENTSWITHOUTASSOCIATED_SUCCESS = 'GET_CLIENTSWITHOUTASSOCIATED_SUCCESS';
export const GET_CLIENTSWITHOUTASSOCIATED_ERROR = 'GET_CLIENTSWITHOUTASSOCIATED_ERROR';

export const GET_CLIENTSWITHASSOCIATED_REQUEST = 'GET_CLIENTSWITHASSOCIATED_REQUEST';
export const GET_CLIENTSWITHASSOCIATED_SUCCESS = 'GET_CLIENTSWITHASSOCIATED_SUCCESS';
export const GET_CLIENTSWITHASSOCIATED_ERROR = 'GET_CLIENTSWITHASSOCIATED_ERROR';

export const POST_CLIENTS_REQUEST = 'POST_CLIENTS_REQUEST';
export const POST_CLIENTS_SUCCESS = 'POST_CLIENTS_SUCCESS';
export const POST_CLIENTS_ERROR = 'POST_CLIENTS_ERROR';

export const DELETE_CLIENTS_REQUEST = 'DELETE_CLIENTS_REQUEST';
export const DELETE_CLIENTS_SUCCESS = 'DELETE_CLIENTS_SUCCESS';
export const DELETE_CLIENTS_ERROR = 'DELETE_CLIENTS_ERROR';

export const GET_PARAMS_REQUEST = 'GET_PARAMS_REQUEST';
export const GET_PARAMS_SUCCESS = 'GET_PARAMS_SUCCESS';
export const GET_PARAMS_ERROR = 'GET_PARAMS_ERROR';

export const POST_PARAM_REQUEST = 'POST_PARAM_REQUEST';
export const POST_PARAM_SUCCESS = 'POST_PARAM_SUCCESS';
export const POST_PARAM_ERROR = 'POST_PARAM_ERROR';

export const PUT_PARAMS_REQUEST = 'PUT_PARAMS_REQUEST';
export const PUT_PARAMS_SUCCESS = 'PUT_PARAMS_SUCCESS';
export const PUT_PARAMS_ERROR = 'PUT_PARAMS_ERROR';

export const GET_BUNDLE_ADMIN_REQUEST = 'GET_BUNDLE_ADMIN_REQUEST';
export const GET_BUNDLE_ADMIN_SUCCESS = 'GET_BUNDLE_ADMIN_SUCCESS';
export const GET_BUNDLE_ADMIN_ERROR = 'GET_BUNDLE_ADMIN_ERROR';

export const GET_BUNDLE_ADMIN_FREE_REQUEST = 'GET_BUNDLE_ADMIN_FREE_REQUEST';
export const GET_BUNDLE_ADMIN_FREE_SUCCESS = 'GET_BUNDLE_ADMIN_FREE_SUCCESS';
export const GET_BUNDLE_ADMIN_FREE_ERROR = 'GET_BUNDLE_ADMIN_FREE_ERROR';

export const POST_BUNDLE_ADMIN_REQUEST = 'POST_BUNDLE_ADMIN_REQUEST';
export const POST_BUNDLE_ADMIN_SUCCESS = 'POST_BUNDLE_ADMIN_SUCCESS';
export const POST_BUNDLE_ADMIN_ERROR = 'POST_BUNDLE_ADMIN_ERROR';

export const DELET_BUNDLE_ADMIN_REQUEST = 'DELET_BUNDLE_ADMIN_REQUEST';
export const DELET_BUNDLE_ADMIN_SUCCESS = 'DELET_BUNDLE_ADMIN_SUCCESS';
export const DELET_BUNDLE_ADMIN_ERROR = 'DELET_BUNDLE_ADMIN_ERROR';

export const GET_DONTMACTH_REQUEST = 'GET_DONTMACTH_REQUES';
export const GET_DONTMACTH_SUCCESS = 'GET_DONTMACTH_SUCCESS';
export const GET_DONTMACTH_ERROR = 'GET_DONTMACTH_ERROR';

export const PUT_DROPSHIPPING_REQUEST = 'PUT_DROPSHIPPING_REQUEST';
export const PUT_DROPSHIPPING_SUCCESS = 'PUT_DROPSHIPPING_SUCCESS';
export const PUT_DROPSHIPPING_ERROR = 'PUT_DROPSHIPPING_ERROR';

export const PUT_DROPSHIPPING_UPDATE_REQUEST = 'PUT_DROPSHIPPING_UPDATE_REQUEST';
export const PUT_DROPSHIPPING_UPDATE_SUCCESS = 'PUT_DROPSHIPPING_UPDATE_SUCCESS';
export const PUT_DROPSHIPPING_UPDATE_ERROR = 'PUT_DROPSHIPPING_UPDATE_ERROR';

// Confirmar en bodega MIA
export const PUT_CONFIRM_WAREHOUSE_REQUEST = 'PUT_CONFIRM_WAREHOUSE_REQUEST';
export const PUT_CONFIRM_WAREHOUSE_SUCCESS = 'PUT_CONFIRM_WAREHOUSE_SUCCESS';
export const PUT_CONFIRM_WAREHOUSE_ERROR = 'PUT_CONFIRM_WAREHOUSE_ERROR';

export const POST_ORDER_DONT_MATCH_REQUEST = 'POST_ORDER_DONT_MATCH_REQUEST';
export const POST_ORDER_DONT_MATCH_SUCCESS = 'POST_ORDER_DONT_MATCH_SUCCESS';
export const POST_ORDER_DONT_MATCH_ERROR = 'POST_ORDER_DONT_MATCH_ERROR';

export const GET_ORDERS_REVIWED_REQUEST = 'GET_ORDERS_REVIWED_REQUEST';
export const GET_ORDERS_REVIWED_SUCCESS = 'GET_ORDERS_REVIWED_SUCCESS';
export const GET_ORDERS_REVIWED_ERROR = 'GET_ORDERS_REVIWED_ERROR';

export const GET_BUNDLE_EXCEL_REQUEST = 'GET_BUNDLE_EXCEL_REQUEST';
export const GET_BUNDLE_EXCEL_SUCCESS = 'GET_BUNDLE_EXCEL_SUCCESS';
export const GET_BUNDLE_EXCEL_ERROR = 'GET_BUNDLE_EXCEL_ERROR';

export const GET_GENERATED_BUNDLES_REQUEST = 'GET_GENERATED_BUNDLES_REQUEST';
export const GET_GENERATED_BUNDLES_SUCCESS = 'GET_GENERATED_BUNDLES_SUCCESS';
export const GET_GENERATED_BUNDLES_ERROR = 'GET_GENERATED_BUNDLES_ERROR';

// Bultos enviado y en bodega UIO
export const GET_BUNDLE_INVOICE_REQUEST = 'GET_BUNDLE_INVOICE_REQUEST';
export const GET_BUNDLE_INVOICE_SUCCESS = 'GET_BUNDLE_INVOICE_SUCCESS';
export const GET_BUNDLE_INVOICE_ERROR = 'GET_BUNDLE_INVOICE_ERROR';

export const GET_BUNDLE_INVOICE_ASSOCIATE_REQUEST = 'GET_BUNDLE_INVOICE_ASSOCIATE_REQUEST';
export const GET_BUNDLE_INVOICE_ASSOCIATE_SUCCESS = 'GET_BUNDLE_INVOICE_ASSOCIATE_SUCCESS';
export const GET_BUNDLE_INVOICE_ASSOCIATE_ERROR = 'GET_BUNDLE_INVOICE_ASSOCIATE_ERROR';

export const GET_BUNDLE_SENDED_REQUEST = 'GET_BUNDLE_SENDED_REQUEST';
export const GET_BUNDLE_SENDED_SUCCESS = 'GET_BUNDLE_SENDED_SUCCESS';
export const GET_BUNDLE_SENDED_ERROR = 'GET_BUNDLE_SENDED_ERROR';

export const PUT_BUNDLE_CONFIRM_ARRIVAL_REQUEST = 'PUT_BUNDLE_CONFIRM_ARRIVAL_REQUEST';
export const PUT_BUNDLE_CONFIRM_ARRIVAL_SUCCESS = 'PUT_BUNDLE_CONFIRM_ARRIVAL_SUCCESS';
export const PUT_BUNDLE_CONFIRM_ARRIVAL_ERROR = 'PUT_BUNDLE_CONFIRM_ARRIVAL_ERROR';

export const PUT_BUNDLE_CONFIRM_WEIGHTS_REQUEST = 'PUT_BUNDLE_CONFIRM_WEIGHTS_REQUEST';
export const PUT_BUNDLE_CONFIRM_WEIGHTS_SUCCESS = 'PUT_BUNDLE_CONFIRM_WEIGHTS_SUCCESS';
export const PUT_BUNDLE_CONFIRM_WEIGHTS_ERROR = 'PUT_BUNDLE_CONFIRM_WEIGHTS_ERROR';

// Order
export const DELET_ORDER_ADMIN_REQUEST = 'DELET_ORDER_ADMIN_REQUEST';
export const DELET_ORDER_ADMIN_SUCCESS = 'DELET_ORDER_ADMIN_SUCCESS';
export const DELET_ORDER_ADMIN_ERROR = 'DELET_ORDER_ADMIN_ERROR';

export const PUT_ORDER_DELIVERY_ADMIN_REQUEST = 'PUT_ORDER_DELIVERY_ADMIN_REQUEST';
export const PUT_ORDER_DELIVERY_ADMIN_SUCCESS = 'PUT_ORDER_DELIVERY_ADMIN_SUCCESS';
export const PUT_ORDER_DELIVERY_ADMIN_ERROR = 'PUT_ORDER_DELIVERY_ADMIN_ERROR';

export const PUT_ORDER_CLIENT_REQUEST = 'PUT_ORDER_CLIENT_REQUEST';
export const PUT_ORDER_CLIENT_SUCCESS = 'PUT_ORDER_CLIENT_SUCCESS';
export const PUT_ORDER_CLIENT_ERROR = 'PUT_ORDER_CLIENT_ERROR';

export const PUT_UPDATE_ORDER_REQUEST = 'PUT_UPDATE_ORDER_REQUEST';
export const PUT_UPDATE_ORDER_ERROR = 'PUT_UPDATE_ORDER_ERROR';
export const PUT_UPDATE_ORDER_SUCCESS = 'PUT_UPDATE_ORDER_SUCCESS';

// ================== Associated ==============================
export const RESET_ASSOCIATED = 'RESET_ASSOCIATED';

export const GET_ORDERS_ASSOCIATED_REQUEST = 'GET_ORDERS_ASSOCIATED_REQUEST';
export const GET_ORDERS_ASSOCIATED_SUCCESS = 'GET_ORDERS_ASSOCIATED_SUCCESS';
export const GET_ORDERS_ASSOCIATED_ERROR = 'GET_ORDERS_ASSOCIATED_ERROR';

export const POST_ORDER_UPDATE_TRACKING_ASSOCIATED_REQUEST = 'POST_ORDER_UPDATE_TRACKING_ASSOCIATED_REQUEST';
export const POST_ORDER_UPDATE_TRACKING_ASSOCIATED_SUCCESS = 'POST_ORDER_UPDATE_TRACKING_ASSOCIATED_SUCCESS';
export const POST_ORDER_UPDATE_TRACKING_ASSOCIATED_ERROR = 'POST_ORDER_UPDATE_TRACKING_ASSOCIATED_ERROR';

export const GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_REQUEST = 'GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_REQUEST';
export const GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_SUCCESS = 'GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_SUCCESS';
export const GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_ERROR = 'GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_ERROR';

export const POST_ORDER_UPDATE_VALUE_ASSOCIATED_REQUEST = 'POST_ORDER_UPDATE_VALUE_ASSOCIATED_REQUEST';
export const POST_ORDER_UPDATE_VALUE_ASSOCIATED_SUCCESS = 'POST_ORDER_UPDATE_VALUE_ASSOCIATED_SUCCESS';
export const POST_ORDER_UPDATE_VALUE_ASSOCIATED_ERROR = 'POST_ORDER_UPDATE_VALUE_ASSOCIATED_ERROR';

export const POST_INCOMPLETE_ORDER_REQUEST = 'POST_INCOMPLETE_ORDER_REQUEST';
export const POST_INCOMPLETE_ORDER_ERROR = 'POST_INCOMPLETE_ORDER_ERROR';
export const POST_INCOMPLETE_ORDER_SUCCESS = 'POST_INCOMPLETE_ORDER_SUCCESS';

// Bundles
export const GET_BUNDLE_REQUEST = 'GET_BUNDLE_REQUEST';
export const GET_BUNDLE_SUCCESS = 'GET_BUNDLE_SUCCESS';
export const GET_BUNDLE_ERROR = 'GET_BUNDLE_ERROR';

export const GET_BUNDLE_ASSOCIATE_REQUEST = 'GET_BUNDLE_ASSOCIATE_REQUEST';
export const GET_BUNDLE_ASSOCIATE_SUCCESS = 'GET_BUNDLE_ASSOCIATE_SUCCESS';
export const GET_BUNDLE_ASSOCIATE_ERROR = 'GET_BUNDLE_ASSOCIATE_ERROR';

export const GET_BUNDLE_FREE_REQUEST = 'GET_BUNDLE_FREE_REQUEST';
export const GET_BUNDLE_FREE_SUCCESS = 'GET_BUNDLE_FREE_SUCCESS';
export const GET_BUNDLE_FREE_ERROR = 'GET_BUNDLE_FREE_ERROR';

export const GET_BUNDLE_PROFIT_REQUEST = 'GET_BUNDLE_PROFIT_REQUEST';
export const GET_BUNDLE_PROFIT_SUCCESS = 'GET_BUNDLE_PROFIT_SUCCESS';
export const GET_BUNDLE_PROFIT_ERROR = 'GET_BUNDLE_PROFIT_ERROR';

export const POST_BUNDLE_REQUEST = 'POST_BUNDLE_REQUEST';
export const POST_BUNDLE_SUCCESS = 'POST_BUNDLE_SUCCESS';
export const POST_BUNDLE_ERROR = 'POST_BUNDLE_ERROR';

export const DELET_BUNDLE_REQUEST = 'DELET_BUNDLE_REQUEST';
export const DELET_BUNDLE_SUCCESS = 'DELET_BUNDLE_SUCCESS';
export const DELET_BUNDLE_ERROR = 'DELET_BUNDLE_ERROR';

export const GET_CLIENTS_OF_ASSOCIATED_REQUEST = 'GET_CLIENTS_OF_ASSOCIATED_REQUEST';
export const GET_CLIENTS_OF_ASSOCIATED_SUCCESS = 'GET_CLIENTS_OF_ASSOCIATED_SUCCESS';
export const GET_CLIENTS_OF_ASSOCIATED_ERROR = 'GET_CLIENTS_OF_ASSOCIATED_ERROR';

export const GET_CLIENTS_ORDERS_OF_ASSOCIATED_REQUEST = 'GET_CLIENTS_ORDERS_OF_ASSOCIATED_REQUEST';
export const GET_CLIENTS_ORDERS_OF_ASSOCIATED_SUCCESS = 'GET_CLIENTS_ORDERS_OF_ASSOCIATED_SUCCESS';
export const GET_CLIENTS_ORDERS_OF_ASSOCIATED_ERROR = 'GET_CLIENTS_ORDERS_OF_ASSOCIATED_ERROR';

export const GET_PROFIT_PAID_REQUEST = 'GET_PROFIT_PAID_REQUEST';
export const GET_PROFIT_PAID_SUCCESS = 'GET_PROFIT_PAID_SUCCESS';
export const GET_PROFIT_PAID_ERROR = 'GET_PROFIT_PAID_ERROR';

export const GET_PROFIT_PENDINGOFPAY_REQUEST = 'GET_PROFIT_PENDINGOFPAY_REQUEST';
export const GET_PROFIT_PENDINGOFPAY_SUCCESS = 'GET_PROFIT_PENDINGOFPAY_SUCCESS';
export const GET_PROFIT_PENDINGOFPAY_ERROR = 'GET_PROFIT_PENDINGOFPAY_ERROR';

// Order
export const DELET_ORDER_ASSOCIATED_REQUEST = 'DELET_ORDER_ASSOCIATED_REQUEST';
export const DELET_ORDER_ASSOCIATED_SUCCESS = 'DELET_ORDER_ASSOCIATED_SUCCESS';
export const DELET_ORDER_ASSOCIATED_ERROR = 'DELET_ORDER_ASSOCIATED_ERROR';
