import * as types from '../../consts/actionTypes';

export const postSigninRequest = payload => ({
	type: types.POST_SIGNIN_REQUEST,
	payload,
});

export const postSignupRequest = payload => ({
	type: types.POST_SIGNUP_REQUEST,
	payload,
});

// export const resetSignupRequest = () => ({
// 	type: types.POST_SIGNUP_SUCCESS
// });

// Usado para setear al usuario logueado desde el index.js
export const setUser = payload => ({
	type: types.POST_SIGNIN_SUCCESS,
	payload,
});

export const clearUser = () => ({
	type: types.RESET_USER,
});

export const getRolesRequest = () => ({
	type: types.GET_ROLES_REQUEST,
});

export const getRoleRequest = () => ({
	type: types.GET_ROLE_REQUEST,
});

export const postResetPasswordRequest = payload => ({
	type: types.POST_RESET_PASSWORD_REQUEST,
	payload,
});

// export const postResetPasswordSuccess = () => ({
// 	type: types.POST_RESET_PASSWORD_SUCCESS
// });

export const postResetPasswordConfirmRequest = payload => ({
	type: types.POST_RESET_PASSWORD_CONFIRM_REQUEST,
	payload,
});

export const postResetPasswordConfirmReset = () => ({
	type: types.POST_RESET_PASSWORD_CONFIRM_SUCCESS,
});

export const putProfileRequest = payload => ({
	type: types.PUT_PROFILE_REQUEST,
	payload,
});

export const putChangePasswordRequest = payload => ({
	type: types.PUT_CHANGE_PASSWORD_REQUEST,
	payload,
});

export const resetProfileAndChangeRequest = () => ({
	type: types.PUT_PROFILE_SUCCESS,
});

export const updateOptionSidebar = payload => ({
	type: types.UPDATE_OPTION_SIDEBAR_REQUEST,
	payload,
});
