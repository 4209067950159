import {
	POST_SIGNIN_ERROR,
	POST_SIGNIN_REQUEST,
	POST_SIGNIN_SUCCESS,
	POST_SIGNUP_ERROR,
	POST_SIGNUP_REQUEST,
	POST_SIGNUP_SUCCESS,
	GET_ROLE_REQUEST,
	GET_ROLE_SUCCESS,
	GET_ROLE_ERROR,
	POST_RESET_PASSWORD_REQUEST,
	POST_RESET_PASSWORD_ERROR,
	POST_RESET_PASSWORD_SUCCESS,
	PUT_PROFILE_REQUEST,
	PUT_PROFILE_ERROR,
	PUT_PROFILE_SUCCESS,
	PUT_CHANGE_PASSWORD_REQUEST,
	PUT_CHANGE_PASSWORD_ERROR,
	PUT_CHANGE_PASSWORD_SUCCESS,
	GET_ROLES_REQUEST,
	GET_ROLES_SUCCESS,
	GET_ROLES_ERROR,
	UPDATE_OPTION_SIDEBAR_REQUEST,
	RESET_USER,
	POST_RESET_PASSWORD_CONFIRM_REQUEST,
	POST_RESET_PASSWORD_CONFIRM_ERROR,
	POST_RESET_PASSWORD_CONFIRM_SUCCESS,
} from '../../consts/actionTypes';

function authReducer(state = {}, action) {
	switch (action.type) {
		case RESET_USER:
			return {};

		case UPDATE_OPTION_SIDEBAR_REQUEST:
			return { ...state, optionSideBar: action.payload.option, subOptionSideBar: action.payload.subOption };
		// case UPDATE_OPTION_SIDEBAR_REQUEST:
		//     return { ...state, optionSideBar: -1, subOptionSideBar: -1 };
		// case UPDATE_OPTION_SIDEBAR_SUCCESS:
		//     if(action.payload){
		//         return { ...state, optionSideBar: action.payload.option, subOptionSideBar: action.payload.subOption };
		//     }
		//     return { ...state, optionSideBar: -1, subOptionSideBar: -1 };

		case POST_SIGNIN_REQUEST:
			return { ...state, isLoading: true, token: null, errorSignin: null };
		case POST_SIGNIN_ERROR:
			return { isLoading: false, token: null, role: null, errorSignin: action.error.response.data.message };
		case POST_SIGNIN_SUCCESS:
			if (action.payload.accessToken) {
				return { ...state, isLoading: false, token: action.payload.accessToken, errorSignin: null };
			}
			return { ...state, isLoading: false, token: action.payload.token, errorSignin: null };

		case POST_SIGNUP_REQUEST:
			return { ...state, isLoading: true, id: null, errorMessages: null };
		case POST_SIGNUP_ERROR:
			return { ...state, isLoading: false, id: null, errorMessages: action.error.response.data.message };
		case POST_SIGNUP_SUCCESS:
			if (action.authData) {
				return { ...state, isLoading: false, id: action.authData.id, errorMessages: null };
			}
			return { ...state, isLoading: false, id: null, errorMessages: null };

		case GET_ROLES_REQUEST:
			return { ...state, isLoadingRoles: true, roles: null, errorRoles: null };
		case GET_ROLES_ERROR:
			return { ...state, isLoadingRoles: false, roles: null, errorRoles: action.error.response.data.message };
		case GET_ROLES_SUCCESS:
			if (action.roles) {
				return { ...state, isLoadingRoles: false, roles: action.roles, errorRoles: null };
			}
			return { ...state, isLoadingRoles: false, roles: null, errorRoles: null };

		case GET_ROLE_REQUEST:
			return { ...state, isLoadingRole: true };
		case GET_ROLE_SUCCESS:
			return {
				...state,
				isLoadingRole: false,
				role: action.roleData.role.isAdmin ? 1 : action.roleData.role.isAssociate ? 2 : 0,
				userData: action.roleData,
			};
		case GET_ROLE_ERROR:
			return { ...state, isLoadingRole: false, token: null, role: null, userData: null };

		case POST_RESET_PASSWORD_REQUEST:
			return { ...state, isLoadingResetPassword: true, okResetPassword: null, errorResetPassword: null };
		case POST_RESET_PASSWORD_ERROR:
			return {
				...state,
				isLoadingResetPassword: false,
				okResetPassword: null,
				errorResetPassword: action.error.response.data.message,
			};
		case POST_RESET_PASSWORD_SUCCESS:
			if (action.resetData) {
				return {
					...state,
					isLoadingResetPassword: false,
					okResetPassword: action.resetData.message,
					errorResetPassword: null,
				};
			}
			return { ...state, isLoadingResetPassword: false, okResetPassword: null, errorResetPassword: null };

		case POST_RESET_PASSWORD_CONFIRM_REQUEST:
			return {
				...state,
				isLoadingResetPasswordConfirm: true,
				okResetPasswordConfirm: null,
				errorResetPasswordConfirm: null,
			};
		case POST_RESET_PASSWORD_CONFIRM_ERROR:
			return {
				...state,
				isLoadingResetPasswordConfirm: false,
				okResetPasswordConfirm: null,
				errorResetPasswordConfirm: action.error.response.data.message,
			};
		case POST_RESET_PASSWORD_CONFIRM_SUCCESS:
			if (action.resetConfirm) {
				return {
					...state,
					isLoadingResetPasswordConfirm: false,
					okResetPasswordConfirm: action.resetConfirm,
					errorResetPasswordConfirm: null,
				};
			}
			return {
				...state,
				isLoadingResetPasswordConfirm: false,
				okResetPasswordConfirm: null,
				errorResetPasswordConfirm: null,
			};

		case PUT_PROFILE_REQUEST:
			return { ...state, isLoadingProfile: true, profile: null, errorProfile: null };
		case PUT_PROFILE_ERROR:
			return { ...state, isLoadingProfile: false, profile: null, errorProfile: action.error.response.data.message };
		case PUT_PROFILE_SUCCESS:
			if (action.profile) {
				return { ...state, isLoadingProfile: false, profile: 1, userData: action.profile, errorProfile: null }; //, token: action.profile};
			}
			return {
				...state,
				isLoadingProfile: false,
				profile: null,
				errorProfile: null,
				changePass: null,
				errorchangePassword: null,
			};

		case PUT_CHANGE_PASSWORD_REQUEST:
			return { ...state, isLoadingChangePassword: true, changePass: null, errorchangePassword: null };
		case PUT_CHANGE_PASSWORD_ERROR:
			return {
				...state,
				isLoadingChangePassword: false,
				changePass: null,
				errorchangePassword: action.error.response.data.message,
			};
		case PUT_CHANGE_PASSWORD_SUCCESS:
			if (action.changePass) {
				return { ...state, isLoadingChangePassword: false, changePass: action.changePass, errorchangePassword: null };
			}
			return { ...state, isLoadingChangePassword: false, changePass: null, errorchangePassword: null };

		default:
			return state;
	}
}

export default authReducer;
