import { DELETE_QUOTE_ERROR, DELETE_QUOTE_REQUEST, DELETE_QUOTE_SUCCESS } from '../../consts/allUsersTypes';

const initialState = {
	isQuoteDropDelete: null,
	isLoadingQuoteDropDelete: false,
	errorQuoteDropDelete: null,
};

function allUsersReducer(state = initialState, action) {
	switch (action.type) {
		case DELETE_QUOTE_REQUEST:
			return { ...state, isLoadingQuoteDropDelete: true, isQuoteDropDelete: null, errorQuoteDropDelete: null };
		case DELETE_QUOTE_ERROR:
			return {
				...state,
				isLoadingQuoteDropDelete: false,
				isQuoteDropDelete: null,
				errorQuoteDropDelete: action.error.data.message,
			};
		case DELETE_QUOTE_SUCCESS:
			return {
				...state,
				isLoadingQuoteDropDelete: false,
				isQuoteDropDelete: action.response,
				errorQuoteDropDelete: null,
			};

		default:
			return state;
	}
}

export default allUsersReducer;
