export const POST_QUOTE_REQUEST = 'POST_QUOTE_REQUEST';
export const POST_QUOTE_SUCCESS = 'POST_QUOTE_SUCCESS';
export const POST_QUOTE_ERROR = 'POST_QUOTE_ERROR';

export const GET_ORDERS_REQUEST = 'GET_ORDERS_REQUEST';
export const GET_ORDERS_SUCCESS = 'GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR = 'GET_ORDERS_ERROR';

export const POST_ORDER_REQUEST = 'POST_ORDER_REQUEST';
export const POST_ORDER_SUCCESS = 'POST_ORDER_SUCCESS';
export const POST_ORDER_ERROR = 'POST_ORDER_ERROR';

export const GET_QUOTE_DROPSHIPPING_REQUEST = 'GET_QUOTE_DROPSHIPPING_REQUEST';
export const GET_QUOTE_DROPSHIPPING_SUCCESS = 'GET_QUOTE_DROPSHIPPING_SUCCESS';
export const GET_QUOTE_DROPSHIPPING_ERROR = 'GET_QUOTE_DROPSHIPPING_ERROR';

export const POST_QUOTE_DROPSHIPPING_REQUEST = 'POST_QUOTE_DROPSHIPPING_REQUEST';
export const POST_QUOTE_DROPSHIPPING_SUCCESS = 'POST_QUOTE_DROPSHIPPING_SUCCESS';
export const POST_QUOTE_DROPSHIPPING_ERROR = 'POST_QUOTE_DROPSHIPPING_ERROR';

export const PATCH_QUOTED_DROPSHIPPING_REQUEST = 'PATCH_QUOTED_DROPSHIPPING_REQUEST';
export const PATCH_QUOTED_DROPSHIPPING_SUCCESS = 'PATCH_QUOTED_DROPSHIPPING_SUCCESS';
export const PATCH_QUOTED_DROPSHIPPING_ERROR = 'PATCH_QUOTED_DROPSHIPPING_ERROR';


export const POST_AUTHORIZATION_REQUEST = 'POST_AUTHORIZATION_REQUEST';
export const POST_AUTHORIZATION_SUCCESS = 'POST_AUTHORIZATION_SUCCESS';
export const POST_AUTHORIZATION_ERROR = 'POST_AUTHORIZATION_ERROR';
