import httpInstance from '../../helpers/http.service';
const { VITE_APP_API } = import.meta.env;

export const getOfAdmin = (url, headers) => {
	return httpInstance()
		.get(VITE_APP_API + url, headers)
		.then(response => {
			return response.data;
		});
};

export const postOfAdmin = (url, data, headers) => {
	return httpInstance()
		.post(VITE_APP_API + url, data, headers)
		.then(response => {
			return response.data;
		});
};

export const putOfAdmin = (url, data, headers) => {
	return httpInstance()
		.put(VITE_APP_API + url, data, headers)
		.then(response => {
			return response.data;
		});
};

export const patchOfAdmin = (url, data, headers) => {
	return httpInstance()
		.patch(VITE_APP_API + url, data, headers)
		.then(response => {
			return response.data;
		});
};

export const deleteOfAdmin = (url, data, headers) => {
	return httpInstance()
		.delete(VITE_APP_API + url, { data }, headers)
		.then(response => {
			return response.data;
		});
};
