import httpInstance from '../../helpers/http.service';
const { VITE_APP_API } = import.meta.env;

export const getOfAssociated = (url, headers) => {
	return httpInstance()
		.get(VITE_APP_API + url, headers)
		.then(response => {
			return response.data;
		});
};

export const postOfAssociated = (url, data, headers) => {
	return httpInstance()
		.post(VITE_APP_API + url, data, headers)
		.then(response => {
			return response.data;
		});
};

export const putOfAssociated = (url, data) => {
	return httpInstance()
		.put(VITE_APP_API + url, data)
		.then(response => {
			return response.data;
		});
};

export const patchOfAssociated = (url, data) => {
	return httpInstance()
		.patch(VITE_APP_API + url, data)
		.then(response => {
			return response.data;
		});
};

export const deleteOfAssociated = (url, data) => {
	return httpInstance()
		.delete(VITE_APP_API + url, { data })
		.then(response => {
			return response.data;
		});
};
