import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import registerServiceWorker from './registerServiceWorker';

import App from './web/App';
import './index.scss';
import './i18n/config';
// import reportWebVitals from './reportWebVitals';

import configureStore from './core/redux/store';
import { setUser, clearUser } from './core/redux/actions/authActions';
const store = configureStore();

const user = localStorage.getItem('snap');
if (user) {
	store.dispatch(setUser({ token: JSON.parse(user).token }));
} else {
	store.dispatch(clearUser());
}

ReactDOM.render(
	<StrictMode>
		<App store={store} />
	</StrictMode>,
	document.getElementById('root')
);
registerServiceWorker();
