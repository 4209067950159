import { takeEvery, takeLatest } from 'redux-saga/effects';
import * as types from '../../consts/actionTypes';
import * as typesClient from '../../consts/clientTypes';
import * as typesAssociated from '../../consts/associatedTypes';
import * as typesAdmin from '../../consts/adminTypes';
import * as typesAllUsers from '../../consts/allUsersTypes';

import {
	postSigninRequestSaga,
	postSignupRequestSaga,
	getRoleRequestSaga,
	postResetPasswordRequestSaga,
	putProfileRequestSaga,
	putChangePasswordRequestSaga,
	getRolesRequestSaga,
	postResetPasswordConfirmRequestSaga,
} from './authSaga';

import {
	postQuoteRequestSaga,
	postOrderRequestSaga,
	getOrdersRequestSaga,
	getMyPackagesRequestSaga,
	getPayPackageRequestSaga,
	putOrderDescriptionRequestSaga,
	postAuthorizedNameRequestSaga,
	getAuthorizedNameRequestSaga,
	postGenerateBillOfSaleSaga,
	getGenerateBillOfSaleSaga,
	postQuoteDropshippingRequestSaga,
	getQuoteDropshippingRequestSaga,
	patchQuotedDropshippingRequestSaga,
	postAuthorizationRequestSaga,
	patchUpdateOrderRequestSaga,
} from './clientSaga';
import {
	deletBundleAdminRequestSaga,
	deleteClientsRequestSaga,
	getAssociatedRequestSaga,
	getBundleAdminFreeRequestSaga,
	getBundleAdminRequestSaga,
	getClientsWithAssociatedRequestSaga,
	getClientsWithOutAssociatedRequestSaga,
	getParamsRequestSaga,
	getUsersRequestSaga,
	postBundleAdminRequestSaga,
	postClientsRequestSaga,
	postParamRequestSaga,
	putParamRequestSaga,
	putUsersRequestSaga,
	confirmWarehouse,
	getOrdersAdminRequestSaga,
	getBundleExcelRequestSaga,
	getBundleInvoiceRequestSaga,
	getBundleSendedRequestSaga,
	putBundleConfirmArrivalRequestSaga,
	getClientsRequestSaga,
	putBundleConfirmWeightslRequestSaga,
	getClientsOrdersRequestSaga,
	putClientPaymentRequestSaga,
	putAssociatePaymentRequestSaga,
	getGeneratedBundlesRequestSaga,
	postOrderDontMatchRequestSaga,
	deleteOrderAdminRequestSaga,
	putOrderDeliveryAdminRequestSaga,
	putOrderClientRequestSaga,
	getDropshippingRequestSaga,
	putDropshippingUpdateRequestSaga,
	patchDropshippingRequestSaga,
	getDontMatchSaga,
	postUploadBundleInvoiceRequestSaga,
	putBundleValueRequestSaga,
} from './adminSaga';
import {
	deletBundleRequestSaga,
	deleteOrderAssociatedRequestSaga,
	getBundleAssociateRequestSaga,
	getBundleFreeRequestSaga,
	getBundleProfitRequestSaga,
	getBundleRequestSaga,
	getClientsOfAssociatedRequestSaga,
	getClientsOrdersOfAssociatedRequestSaga,
	getOrdersInWarehouseMiaRequestSaga,
	getOrdersToTrackingRequestSaga,
	getProfitPaidRequestSaga,
	getProfitPendingOfPayRequestSaga,
	postBundleRequestSaga,
	putOrdersToUpdateTrackingRequestSaga,
	postOrdersToUpdateValueRequestSaga,
	getDropshippingAssociatedRequestSaga,
	patchDropshippingAssociatedRequestSaga,
	putDropshippingAssociatedSaga,
	postIncompleteOrderRequestSaga,
	getPurchaseAssociatedRequestSaga,
	patchPurchaseAssociatedRequestSaga,
	postApprovePurchaseAssociatedRequestSaga,
	postReviewPurchaseAssociatedRequestSaga,
	deleteItemPurchaseAssociatedRequestSaga,
	getPurchaseIdAssociatedRequestSaga,
	postUploadBundleInvoiceAssociateRequestSaga,
	putBundleValueAssociateRequestSaga,
	getBundleInvoiceAssociateRequestSaga,
} from './associatedSaga';
import { deleteQuoteAllUsersRequestSaga } from './allUsersSaga';
// import { activateAlertSaga } from './utilsSaga';

export default function* watchAuth() {
	// AUTH
	yield takeLatest(types.POST_SIGNIN_REQUEST, postSigninRequestSaga);
	yield takeLatest(types.POST_SIGNUP_REQUEST, postSignupRequestSaga);
	yield takeLatest(types.GET_ROLES_REQUEST, getRolesRequestSaga);
	yield takeLatest(types.GET_ROLE_REQUEST, getRoleRequestSaga);
	yield takeLatest(types.POST_RESET_PASSWORD_REQUEST, postResetPasswordRequestSaga);
	yield takeLatest(types.POST_RESET_PASSWORD_CONFIRM_REQUEST, postResetPasswordConfirmRequestSaga);
	yield takeLatest(types.PUT_PROFILE_REQUEST, putProfileRequestSaga);
	yield takeLatest(types.PUT_CHANGE_PASSWORD_REQUEST, putChangePasswordRequestSaga);
	// yield takeLatest(types.UPDATE_OPTION_SIDEBAR_REQUEST, updateOptionSidebarSaga);

	// CLIENT
	yield takeLatest(typesClient.POST_QUOTE_REQUEST, postQuoteRequestSaga);
	yield takeLatest(typesClient.GET_ORDERS_REQUEST, getOrdersRequestSaga);
	yield takeLatest(typesClient.POST_ORDER_REQUEST, postOrderRequestSaga);
	yield takeEvery(typesClient.GET_QUOTE_DROPSHIPPING_REQUEST, getQuoteDropshippingRequestSaga);
	yield takeEvery(typesClient.POST_QUOTE_DROPSHIPPING_REQUEST, postQuoteDropshippingRequestSaga);
	yield takeEvery(typesClient.PATCH_QUOTED_DROPSHIPPING_REQUEST, patchQuotedDropshippingRequestSaga);
	yield takeLatest(types.POST_AUTHORIZED_NAME_REQUEST, postAuthorizedNameRequestSaga);
	yield takeLatest(types.PUT_ORDER_DESCRIPTION_REQUEST, putOrderDescriptionRequestSaga);
	yield takeLatest(types.GET_MY_PACKAGES_REQUEST, getMyPackagesRequestSaga);
	yield takeLatest(types.GET_PAY_PACKAGE_REQUEST, getPayPackageRequestSaga);
	yield takeLatest(types.GET_AUTHORIZED_NAME_REQUEST, getAuthorizedNameRequestSaga);
	yield takeLatest(types.POST_GENERATE_BILL_OF_SALE_REQUEST, postGenerateBillOfSaleSaga);
	yield takeLatest(types.GET_GENERATE_BILL_OF_SALE_REQUEST, getGenerateBillOfSaleSaga);
	yield takeLatest(typesClient.POST_AUTHORIZATION_REQUEST, postAuthorizationRequestSaga);

	// ADMIN
	yield takeLatest(types.GET_USERS_REQUEST, getUsersRequestSaga);
	yield takeLatest(types.PUT_USERS_REQUEST, putUsersRequestSaga);
	yield takeLatest(types.GET_CLIENTS_REQUEST, getClientsRequestSaga);
	yield takeLatest(types.GET_CLIENTS_ORDERS_REQUEST, getClientsOrdersRequestSaga);
	yield takeLatest(types.PUT_CLIENT_PAYMENT_REQUEST, putClientPaymentRequestSaga);
	yield takeLatest(types.PUT_ASSOCIATE_PAYMENT_REQUEST, putAssociatePaymentRequestSaga);
	yield takeLatest(types.GET_ASSOCIATED_REQUEST, getAssociatedRequestSaga);
	yield takeLatest(types.GET_CLIENTSWITHOUTASSOCIATED_REQUEST, getClientsWithOutAssociatedRequestSaga);
	yield takeLatest(types.GET_CLIENTSWITHASSOCIATED_REQUEST, getClientsWithAssociatedRequestSaga);
	yield takeEvery(types.POST_CLIENTS_REQUEST, postClientsRequestSaga);
	yield takeEvery(types.DELETE_CLIENTS_REQUEST, deleteClientsRequestSaga);
	yield takeLatest(types.GET_PARAMS_REQUEST, getParamsRequestSaga);
	yield takeLatest(types.POST_PARAM_REQUEST, postParamRequestSaga);
	yield takeLatest(types.PUT_PARAMS_REQUEST, putParamRequestSaga);
	yield takeLatest(types.GET_BUNDLE_ADMIN_REQUEST, getBundleAdminRequestSaga);
	yield takeLatest(types.GET_BUNDLE_ADMIN_FREE_REQUEST, getBundleAdminFreeRequestSaga);
	yield takeLatest(types.POST_BUNDLE_ADMIN_REQUEST, postBundleAdminRequestSaga);
	yield takeLatest(types.DELET_BUNDLE_ADMIN_REQUEST, deletBundleAdminRequestSaga);
	yield takeLatest(types.PUT_CONFIRM_WAREHOUSE_REQUEST, confirmWarehouse);
	yield takeLatest(types.POST_ORDER_DONT_MATCH_REQUEST, postOrderDontMatchRequestSaga);
	yield takeLatest(types.GET_ORDERS_REVIWED_REQUEST, getOrdersAdminRequestSaga);
	yield takeLatest(types.GET_BUNDLE_EXCEL_REQUEST, getBundleExcelRequestSaga);
	yield takeLatest(types.GET_BUNDLE_INVOICE_REQUEST, getBundleInvoiceRequestSaga);
	yield takeLatest(types.GET_BUNDLE_SENDED_REQUEST, getBundleSendedRequestSaga);
	yield takeLatest(types.PUT_BUNDLE_CONFIRM_ARRIVAL_REQUEST, putBundleConfirmArrivalRequestSaga);
	yield takeLatest(types.PUT_BUNDLE_CONFIRM_WEIGHTS_REQUEST, putBundleConfirmWeightslRequestSaga);
	yield takeLatest(types.GET_GENERATED_BUNDLES_REQUEST, getGeneratedBundlesRequestSaga);
	yield takeLatest(types.DELET_ORDER_ADMIN_REQUEST, deleteOrderAdminRequestSaga);
	yield takeLatest(types.PUT_ORDER_DELIVERY_ADMIN_REQUEST, putOrderDeliveryAdminRequestSaga);
	yield takeLatest(types.PUT_ORDER_CLIENT_REQUEST, putOrderClientRequestSaga);
	yield takeLatest(types.GET_DONTMACTH_REQUEST, getDontMatchSaga);
	yield takeEvery(types.PUT_DROPSHIPPING_REQUEST, patchDropshippingRequestSaga);
	yield takeEvery(typesAdmin.GET_DROPSHIPPING_REQUEST, getDropshippingRequestSaga);
	yield takeEvery(types.PUT_DROPSHIPPING_UPDATE_REQUEST, putDropshippingUpdateRequestSaga);
	yield takeLatest(typesAdmin.POST_UPLOAD_BUNDLE_INVOICE_REQUEST, postUploadBundleInvoiceRequestSaga)
	yield takeLatest(typesAdmin.PUT_BUNDLE_VALUE_REQUEST, putBundleValueRequestSaga);

	// ASSOCIATED
	yield takeLatest(typesAssociated.GET_PURCHASE_ASSOCIATED_REQUEST, getPurchaseAssociatedRequestSaga);
	yield takeLatest(typesAssociated.GET_PURCHASE_ID_ASSOCIATED_REQUEST, getPurchaseIdAssociatedRequestSaga);
	yield takeLatest(typesAssociated.PATCH_PURCHASE_ASSOCIATED_REQUEST, patchPurchaseAssociatedRequestSaga);
	yield takeLatest(typesAssociated.DELETE_ITEM_PURCHASE_ASSOCIATED_REQUEST, deleteItemPurchaseAssociatedRequestSaga);
	yield takeLatest(typesAssociated.POST_APPROVE_PURCHASE_ASSOCIATED_REQUEST, postApprovePurchaseAssociatedRequestSaga);
	yield takeLatest(typesAssociated.POST_REVIEW_PURCHASE_ASSOCIATED_REQUEST, postReviewPurchaseAssociatedRequestSaga);
	yield takeLatest(types.GET_ORDERS_ASSOCIATED_REQUEST, getOrdersToTrackingRequestSaga);
	yield takeLatest(types.POST_ORDER_UPDATE_TRACKING_ASSOCIATED_REQUEST, putOrdersToUpdateTrackingRequestSaga);
	yield takeLatest(types.GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_REQUEST, getOrdersInWarehouseMiaRequestSaga);
	yield takeLatest(types.POST_ORDER_UPDATE_VALUE_ASSOCIATED_REQUEST, postOrdersToUpdateValueRequestSaga);
	yield takeLatest(types.GET_BUNDLE_REQUEST, getBundleRequestSaga);
	yield takeLatest(types.GET_BUNDLE_ASSOCIATE_REQUEST, getBundleAssociateRequestSaga);
	yield takeLatest(types.GET_BUNDLE_PROFIT_REQUEST, getBundleProfitRequestSaga);
	yield takeLatest(types.GET_BUNDLE_FREE_REQUEST, getBundleFreeRequestSaga);
	yield takeLatest(types.POST_BUNDLE_REQUEST, postBundleRequestSaga);
	yield takeLatest(types.DELET_BUNDLE_REQUEST, deletBundleRequestSaga);
	yield takeLatest(types.GET_CLIENTS_OF_ASSOCIATED_REQUEST, getClientsOfAssociatedRequestSaga);
	yield takeLatest(types.GET_CLIENTS_ORDERS_OF_ASSOCIATED_REQUEST, getClientsOrdersOfAssociatedRequestSaga);
	yield takeLatest(types.GET_PROFIT_PAID_REQUEST, getProfitPaidRequestSaga);
	yield takeLatest(types.GET_PROFIT_PENDINGOFPAY_REQUEST, getProfitPendingOfPayRequestSaga);
	yield takeLatest(types.DELET_ORDER_ASSOCIATED_REQUEST, deleteOrderAssociatedRequestSaga);
	yield takeLatest(typesAssociated.GET_DROPSHIPPING_ASSOCIATED_REQUEST, getDropshippingAssociatedRequestSaga);
	yield takeLatest(typesAssociated.PATCH_DROPSHIPPING_ASSOCIATED_REQUEST, patchDropshippingAssociatedRequestSaga);
	yield takeLatest(typesAssociated.PUT_DROPSHIPPING_ASSOCIATED_REQUEST, putDropshippingAssociatedSaga);
	yield takeLatest(types.POST_INCOMPLETE_ORDER_REQUEST, postIncompleteOrderRequestSaga);
	yield takeLatest(types.PUT_UPDATE_ORDER_REQUEST, patchUpdateOrderRequestSaga); // AQUI
	yield takeLatest(typesAssociated.POST_UPLOAD_BUNDLE_INVOICE_REQUEST, postUploadBundleInvoiceAssociateRequestSaga);
	yield takeLatest(typesAssociated.PUT_BUNDLE_VALUE_REQUEST, putBundleValueAssociateRequestSaga);
	yield takeLatest(types.GET_BUNDLE_INVOICE_ASSOCIATE_REQUEST, getBundleInvoiceAssociateRequestSaga);

	// UTILS
	// yield takeLatest(types_utils.ACTIVATE_ALERT_ASYNC, activateAlertSaga);

	// All User
	yield takeLatest(typesAllUsers.DELETE_QUOTE_REQUEST, deleteQuoteAllUsersRequestSaga);
}
