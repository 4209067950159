import { styled } from '@mui/material/styles';
import { Drawer, Box } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import Options from './Options';
import PropTypes from 'prop-types';

const drawerWidth = 250;

const openedMixin = theme => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = theme => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(7)} + 1px)`,
	},
});

const DrawerWeb = styled(MuiDrawer, { shouldForwardProp: prop => prop !== 'open' })(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

MiniDrawer.propTypes = {
	window: PropTypes.func,
	drawerOpen: PropTypes.bool,
	drawerToggle: PropTypes.func,
	drawerSetting: PropTypes.func,
	drawerSubOpen: PropTypes.bool,
	drawerSubToggle: PropTypes.func,
};

export default function MiniDrawer(props) {
	const { window, drawerOpen, drawerToggle, drawerSetting, drawerSubOpen, drawerSubToggle } = props;
	const container = window !== undefined ? () => window().document.body : undefined;

	return (
		// <>
		<Box component='nav' sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }} aria-label='mailbox folders'>
			<Drawer
				container={container}
				variant='temporary'
				open={drawerOpen}
				onClose={drawerToggle}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
				sx={{
					display: { xs: 'block', sm: 'none' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}
			>
				<Options
					open={drawerOpen}
					onClose={drawerToggle}
					subOpen={drawerSubOpen}
					subOnClose={drawerSubToggle}
					option='1'
				/>
			</Drawer>

			<DrawerWeb
				variant='permanent'
				open={drawerOpen}
				sx={{
					display: { xs: 'none', sm: 'block' },
					'& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
				}}
			>
				<Options
					open={drawerOpen}
					onClose={drawerToggle}
					openSettings={drawerSetting}
					subOpen={drawerSubOpen}
					subOnClose={drawerSubToggle}
					option='2'
				/>
			</DrawerWeb>
		</Box>
	);
}
