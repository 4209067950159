import * as types from '../../consts/actionTypes';
import * as typesAssociated from '../../consts/associatedTypes';

export const clearAssociated = payload => ({
	type: types.RESET_ASSOCIATED,
	payload,
});

export const getPurchaseAssociatedRequest = payload => ({
	type: typesAssociated.GET_PURCHASE_ASSOCIATED_REQUEST,
	payload,
});

export const getPurchaseIdAssociatedRequest = payload => ({
	type: typesAssociated.GET_PURCHASE_ID_ASSOCIATED_REQUEST,
	payload,
});

export const patchPurchaseAssociatedRequest = payload => ({
	type: typesAssociated.PATCH_PURCHASE_ASSOCIATED_REQUEST,
	payload,
});

export const deletePurchaseAssociatedRequest = payload => ({
	type: typesAssociated.DELETE_ITEM_PURCHASE_ASSOCIATED_REQUEST,
	payload,
});

export const postApprovePurchaseAssociatedRequest = payload => ({
	type: typesAssociated.POST_APPROVE_PURCHASE_ASSOCIATED_REQUEST,
	payload,
});

export const postReviewPurchaseAssociatedRequest = payload => ({
	type: typesAssociated.POST_REVIEW_PURCHASE_ASSOCIATED_REQUEST,
	payload,
});

export const getOrdersToTrackingRequest = payload => ({
	type: types.GET_ORDERS_ASSOCIATED_REQUEST,
	payload,
});

export const putOrdersToUpdateTrackingRequest = payload => ({
	type: types.POST_ORDER_UPDATE_TRACKING_ASSOCIATED_REQUEST,
	payload,
});

export const getOrdersInWarehouseMiaRequest = payload => ({
	type: types.GET_ORDERS_IN_WAREHOUSE_MIA_ASSOCIATED_REQUEST,
	payload,
});

export const postOrdersToUpdateValueRequest = payload => ({
	type: types.POST_ORDER_UPDATE_VALUE_ASSOCIATED_REQUEST,
	payload,
});

// Proceso de asignacion o creación de bultos
export const getBundleRequest = () => ({
	type: types.GET_BUNDLE_REQUEST,
});

export const getBundleAssociateRequest = () => ({
	type: types.GET_BUNDLE_ASSOCIATE_REQUEST,
});

export const getBundleFreeRequest = payload => ({
	type: types.GET_BUNDLE_FREE_REQUEST,
	payload,
});

export const getBundleProfitRequest = () => ({
	type: types.GET_BUNDLE_PROFIT_REQUEST,
});

export const postBundleRequest = payload => ({
	type: types.POST_BUNDLE_REQUEST,
	payload,
});

export const deletBundleRequest = payload => ({
	type: types.DELET_BUNDLE_REQUEST,
	payload,
});

export const resetBundleRequest = () => ({
	type: types.DELET_BUNDLE_SUCCESS,
});

export const getClientsOfAssociatedRequest = () => ({
	type: types.GET_CLIENTS_OF_ASSOCIATED_REQUEST,
});

export const getClientsOrdersOfAssociatedRequest = payload => ({
	type: types.GET_CLIENTS_ORDERS_OF_ASSOCIATED_REQUEST,
	payload,
});

export const getProfitPaidRequest = () => ({
	type: types.GET_PROFIT_PAID_REQUEST,
});

export const getProfitPendingOfPayRequest = () => ({
	type: types.GET_PROFIT_PENDINGOFPAY_REQUEST,
});

export const resetProfitPaidRequest = () => ({
	type: types.GET_PROFIT_PAID_SUCCESS,
});

export const resetProfitPendingOfPayRequest = () => ({
	type: types.GET_PROFIT_PENDINGOFPAY_SUCCESS,
});

// Order
export const deleteOrderAssociatedRequest = payload => ({
	type: types.DELET_ORDER_ASSOCIATED_REQUEST,
	payload,
});

export const resetDeleteOrderAssociatedRequest = () => ({
	type: types.DELET_ORDER_ASSOCIATED_SUCCESS,
});

export const postIncompleteOrderRequest = payload => ({
	type: types.POST_INCOMPLETE_ORDER_REQUEST,
	payload,
});

// dropshipping
export const getDropshippingAssociatedRequest = payload => ({
	type: typesAssociated.GET_DROPSHIPPING_ASSOCIATED_REQUEST,
	payload,
});

export const patchDropshippingAssociatedRequest = payload => ({
	type: typesAssociated.PATCH_DROPSHIPPING_ASSOCIATED_REQUEST,
	payload,
});

export const putDropshippingAssociatedRequest = payload => ({
	type: typesAssociated.PUT_DROPSHIPPING_ASSOCIATED_REQUEST,
	payload,
});

export const getBundleInvoiceRequest = payload => ({
	type: types.GET_BUNDLE_INVOICE_ASSOCIATE_REQUEST,
	payload,
});

export const postUploadBundleInvoceRequest = payload => ({
	type: typesAssociated.POST_UPLOAD_BUNDLE_INVOICE_REQUEST,
	payload,
});

export const putBundleValueRequest = payload => ({
	type: typesAssociated.PUT_BUNDLE_VALUE_REQUEST,
	payload,
});