import {
	RESET_CLIENT,
	GET_MY_PACKAGES_SUCCESS,
	GET_MY_PACKAGES_ERROR,
	GET_MY_PACKAGES_REQUEST,
	GET_PAY_PACKAGE_REQUEST,
	GET_PAY_PACKAGE_ERROR,
	GET_PAY_PACKAGE_SUCCESS,
	PUT_ORDER_DESCRIPTION_REQUEST,
	PUT_ORDER_DESCRIPTION_SUCCESS,
	PUT_ORDER_DESCRIPTION_ERROR,
	POST_AUTHORIZED_NAME_REQUEST,
	POST_AUTHORIZED_NAME_ERROR,
	POST_AUTHORIZED_NAME_SUCCESS,
	GET_AUTHORIZED_NAME_REQUEST,
	GET_AUTHORIZED_NAME_ERROR,
	GET_AUTHORIZED_NAME_SUCCESS,
	GET_GENERATE_BILL_OF_SALE_REQUEST,
	GET_GENERATE_BILL_OF_SALE_ERROR,
	GET_GENERATE_BILL_OF_SALE_SUCCESS,
	POST_GENERATE_BILL_OF_SALE_REQUEST,
	POST_GENERATE_BILL_OF_SALE_ERROR,
	POST_GENERATE_BILL_OF_SALE_SUCCESS,
	PUT_UPDATE_ORDER_REQUEST,
	PUT_UPDATE_ORDER_ERROR,
	PUT_UPDATE_ORDER_SUCCESS,
} from '../../consts/actionTypes';
import {
	GET_ORDERS_ERROR,
	GET_ORDERS_REQUEST,
	GET_ORDERS_SUCCESS,
	GET_QUOTE_DROPSHIPPING_ERROR,
	GET_QUOTE_DROPSHIPPING_REQUEST,
	GET_QUOTE_DROPSHIPPING_SUCCESS,
	PATCH_QUOTED_DROPSHIPPING_ERROR,
	PATCH_QUOTED_DROPSHIPPING_REQUEST,
	PATCH_QUOTED_DROPSHIPPING_SUCCESS,
	POST_AUTHORIZATION_ERROR,
	POST_AUTHORIZATION_REQUEST,
	POST_AUTHORIZATION_SUCCESS,
	POST_ORDER_ERROR,
	POST_ORDER_REQUEST,
	POST_ORDER_SUCCESS,
	POST_QUOTE_DROPSHIPPING_ERROR,
	POST_QUOTE_DROPSHIPPING_REQUEST,
	POST_QUOTE_DROPSHIPPING_SUCCESS,
	POST_QUOTE_ERROR,
	POST_QUOTE_REQUEST,
	POST_QUOTE_SUCCESS,
} from '../../consts/clientTypes';

const initialState = {
	quoteDrop: null,
	isQuoteDropLoading: false,
	errorQuoteDrop: null,

	isCreatedQuoteDrop: null,
	isCreatedQuoteDropLoading: false,
	errorCreatedQuoteDrop: null,

	isPatchQuotedDrop: null,
	isPatchQuotedDropLoading: false,
	errorPatchQuotedDrop: null,
};

function clientReducer(state = initialState, action) {
	switch (action.type) {
		case RESET_CLIENT:
			return {};

		case POST_QUOTE_REQUEST:
			return { ...state, isLoading: true, okQuote: null, error: null };
		case POST_QUOTE_ERROR:
			return { ...state, isLoading: false, okQuote: null, error: action.error.response.data.message };
		case POST_QUOTE_SUCCESS:
			if (action.clientData) {
				return { ...state, isLoading: false, okQuote: action.clientData, error: null };
			}
			return { ...state, isLoading: false, okQuote: null, error: null };

		case GET_ORDERS_REQUEST:
			return { ...state, isOrdersLoading: true, orders: null, error: null, method: 'get', ok: null };
		case GET_ORDERS_ERROR:
			return { ...state, isOrdersLoading: false, orders: null, error: action.error.response?.data?.message };
		case GET_ORDERS_SUCCESS:
			if (action.orders) {
				return { ...state, isOrdersLoading: false, orders: action.orders, error: null };
			}
			return { ...state, isOrdersLoading: false, orders: null, error: null };

		case POST_ORDER_REQUEST:
			return { ...state, isLoading: true, ok: null, error: null, method: 'post' };
		case POST_ORDER_ERROR:
			return { ...state, isLoading: false, ok: null, error: action.error.response?.data?.message };
		case POST_ORDER_SUCCESS:
			if (action.ok) {
				return { ...state, isLoading: false, ok: action.ok, orders: null, error: null };
			}
			return { ...state, isLoading: false, ok: null, error: null };

		case GET_QUOTE_DROPSHIPPING_REQUEST:
			return {
				...state,
				isQuoteDropLoading: !state.quoteDrop,
				quoteDrop: state.quoteDrop,
				isCreatedQuoteDrop: null,
				errorQuoteDrop: null,
			};
		case GET_QUOTE_DROPSHIPPING_ERROR:
			return { ...state, isQuoteDropLoading: false, quoteDrop: null, errorQuoteDrop: null };
		case GET_QUOTE_DROPSHIPPING_SUCCESS:
			if (action.dropshipping) {
				return {
					...state,
					isQuoteDropLoading: false,
					quoteDrop: action.dropshipping,
					isCreatedQuoteDrop: null,
					errorQuoteDrop: null,
				};
			}
			return { ...state, isQuoteDropLoading: false, quoteDrop: null, isCreatedQuoteDrop: null, errorQuoteDrop: null };

		case POST_QUOTE_DROPSHIPPING_REQUEST:
			return { ...state, isCreatedQuoteDropLoading: true, isCreatedQuoteDrop: null, errorCreatedQuoteDrop: null };
		case POST_QUOTE_DROPSHIPPING_ERROR:
			return { ...state, isCreatedQuoteDropLoading: false, isCreatedQuoteDrop: null, errorCreatedQuoteDrop: null };
		case POST_QUOTE_DROPSHIPPING_SUCCESS:
			if (action.created) {
				// isCreatedQuoteDrop: action.created, errorCreatedQuoteDrop: null para seguir el proceso pero no se usan
				return {
					...state,
					isCreatedQuoteDropLoading: false,
					isCreatedQuoteDrop: action.created,
					errorCreatedQuoteDrop: null,
				};
			}
			return { ...state, isCreatedQuoteDropLoading: false, isCreatedQuoteDrop: null, errorCreatedQuoteDrop: null };

		case PATCH_QUOTED_DROPSHIPPING_REQUEST:
			return { ...state, isPatchQuotedDropLoading: true, isPatchQuotedDrop: null, errorPatchQuotedDrop: null };
		case PATCH_QUOTED_DROPSHIPPING_ERROR:
			return { ...state, isPatchQuotedDropLoading: false, isPatchQuotedDrop: null, errorPatchQuotedDrop: null };
		case PATCH_QUOTED_DROPSHIPPING_SUCCESS:
			if (action.updated) {
				return {
					...state,
					isPatchQuotedDropLoading: false,
					isPatchQuotedDrop: action.updated,
					errorPatchQuotedDrop: null,
				};
			}
			return { ...state, isPatchQuotedDropLoading: false, isPatchQuotedDrop: null, errorPatchQuotedDrop: null };

		case POST_AUTHORIZED_NAME_REQUEST:
			return { ...state, isLoadingPostAuthorizedName: true, okAuthorizedName: null, errorPostAuthorizedName: null };
		case POST_AUTHORIZED_NAME_ERROR:
			return {
				...state,
				isLoadingPostAuthorizedName: false,
				okAuthorizedName: null,
				errorPostAuthorizedName: action.error.response.data.message,
			};
		case POST_AUTHORIZED_NAME_SUCCESS:
			if (action.okAuthorizedName) {
				return {
					...state,
					isLoadingPostAuthorizedName: false,
					okAuthorizedName: action.okAuthorizedName,
					errorPostAuthorizedName: null,
					myAuthorizedName: null,
				};
			}
			return {
				...state,
				isLoadingPostAuthorizedName: false,
				okAuthorizedName: null,
				errorPostAuthorizedName: null,
				myAuthorizedName: null,
			};

		case GET_MY_PACKAGES_REQUEST:
			return {
				...state,
				isMyPackagesLoading: true,
				myPackages: state.myPackages,
				errorMyPackages: null,
				putOrder: null,
				errorPutOrder: null,
			};
		case GET_MY_PACKAGES_ERROR:
			return {
				...state,
				isMyPackagesLoading: false,
				myPackages: null,
				errorMyPackages: action.error.response?.data?.message,
			};
		case GET_MY_PACKAGES_SUCCESS:
			if (action.orders) {
				return { ...state, isMyPackagesLoading: false, myPackages: action.orders, errorMyPackages: null };
			}
			return { ...state, isMyPackagesLoading: false, myPackages: null, errorMyPackages: null };

		case PUT_ORDER_DESCRIPTION_REQUEST:
			return { ...state, isLoadingPutOrder: true, putOrder: null, errorPutOrder: null };
		case PUT_ORDER_DESCRIPTION_ERROR:
			return {
				...state,
				isLoadingPutOrder: false,
				putOrder: null,
				errorPutOrder: action.error.response?.data?.message,
			};
		case PUT_ORDER_DESCRIPTION_SUCCESS:
			if (action.putOrder) {
				return { ...state, isLoadingPutOrder: false, putOrder: action.putOrder, myPackages: null, errorPutOrder: null };
			}
			return { ...state, isLoadingPutOrder: false, putOrder: null, errorPutOrder: null };

		case GET_AUTHORIZED_NAME_REQUEST:
			return { ...state, isAuthorizedNameLoading: true, myAuthorizedName: null, errorAuthorizedName: null };
		case GET_AUTHORIZED_NAME_ERROR:
			return {
				...state,
				isAuthorizedNameLoading: false,
				myAuthorizedName: null,
				errorAuthorizedName: action.error.response?.data?.message,
			};
		case GET_AUTHORIZED_NAME_SUCCESS:
			if (action.authorizedName) {
				return {
					...state,
					isAuthorizedNameLoading: false,
					myAuthorizedName: action.authorizedName,
					errorAuthorizedName: null,
				};
			}
			return { ...state, isAuthorizedNameLoading: false, myAuthorizedName: null, errorAuthorizedName: null };

		case GET_PAY_PACKAGE_REQUEST:
			return { ...state, isPayPackagesLoading: true, payPackages: null, errorPayPackages: null };
		case GET_PAY_PACKAGE_ERROR:
			return {
				...state,
				isPayPackagesLoading: false,
				payPackages: null,
				errorPayPackages: action.error.response?.data?.message,
			};
		case GET_PAY_PACKAGE_SUCCESS:
			if (action.orders) {
				return { ...state, isPayPackagesLoading: false, payPackages: action.orders, errorPayPackages: null };
			}
			return { ...state, isPayPackagesLoading: false, payPackages: null, errorPayPackages: null };

		case POST_GENERATE_BILL_OF_SALE_REQUEST:
			return { ...state, isGenerateBillLoading: true, generateBill: null, errorGenerateBill: null };
		case POST_GENERATE_BILL_OF_SALE_ERROR:
			return {
				...state,
				isGenerateBillLoading: false,
				generateBill: null,
				errorGenerateBill: action.error.response?.data?.message,
			};
		case POST_GENERATE_BILL_OF_SALE_SUCCESS:
			if (action.billOfSale) {
				return {
					...state,
					isGenerateBillLoading: false,
					generateBill: action.billOfSale,
					errorGenerateBill: null,
					payPackages: null,
				};
			}
			return { ...state, isGenerateBillLoading: false, generateBill: null, errorGenerateBill: null };

		case GET_GENERATE_BILL_OF_SALE_REQUEST:
			return { ...state, isBillOfSaleLoading: true, billOfSale: null, errorBillOfSale: null };
		case GET_GENERATE_BILL_OF_SALE_ERROR:
			return {
				...state,
				isBillOfSaleLoading: false,
				billOfSale: null,
				errorBillOfSale: action.error.response?.data?.message,
			};
		case GET_GENERATE_BILL_OF_SALE_SUCCESS:
			if (action.billOfSale) {
				return { ...state, isBillOfSaleLoading: false, billOfSale: action.billOfSale, errorBillOfSale: null };
			}
			return { ...state, isBillOfSaleLoading: false, billOfSale: null, errorBillOfSale: null };


		case POST_AUTHORIZATION_REQUEST:
			return { ...state, isAuthorization: true, authorization: null, errorAuthorization: null };
		case POST_AUTHORIZATION_ERROR:
			return {
				...state,
				isAuthorization: false,
				Authorization: null,
				errorAuthorization: action.error.response?.data?.message,
			};
		case POST_AUTHORIZATION_SUCCESS:
			if (action.authorization) {
				return {
					...state,
					isAuthorization: false,
					authorization: action.authorization,
					errorAuthorization: null,
					payPackages: null,
				};
			}
			return { ...state, isAuthorization: false, Authorization: null, errorAuthorization: null };
	

		case PUT_UPDATE_ORDER_REQUEST:
			return {
				...state,
				isPutUpdateOrderLoading: true,
				putUpdateOrder: null,
				errorPutUpdateOrder: null,
			};
		case PUT_UPDATE_ORDER_ERROR:
			return {
				...state,
				isPutUpdateOrderLoading: false,
				putUpdateOrder: null,
				errorPutUpdateOrder: action.error.response?.data?.message,
			};
		case PUT_UPDATE_ORDER_SUCCESS:
			if (action.response) {
				return {
					...state,
					isPutUpdateOrderLoading: false,
					putUpdateOrder: action.response,
					errorPutUpdateOrder: null,
				};
			}
			return {
				...state,
				isPutUpdateOrderLoading: false,
				putUpdateOrder: null,
				errorPutUpdateOrder: null,
			};





		default:
			return state;




	}
}

export default clientReducer;
