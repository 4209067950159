import { put, call } from 'redux-saga/effects';
import * as types from '../../../consts/admin/chargesTypes';
import { ACTIVATE_ALERT } from '../../../consts/utilsTypes';
import { deleteOfAdmin, getOfAdmin, postOfAdmin, putOfAdmin } from '../../api/admin';

export function* getAllChargesRequestSaga() {
	try {
		const charges = yield call(getOfAdmin, '/charges');
		yield put({ type: types.GET_ALL_CHARGES_SUCCESS, charges });
	} catch (error) {
		yield put({ type: types.GET_ALL_CHARGES_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message, type: 'error' } });
	}
}

export function* postChargeRequestSaga({ payload }) {
	try {
		yield call(postOfAdmin, '/charges', payload.body);
		yield put({ type: types.POST_CHARGE_SUCCESS });
		yield call(getAllChargesRequestSaga)
	} catch (error) {
		yield put({ type: types.POST_CHARGE_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message?.toString(), type: 'error' } });
	}
}

export function* putChargeRequestSaga({ payload }) {
	try {
		yield call(putOfAdmin, `/charges/${payload.id}`, payload.body);
		yield put({ type: types.PUT_CHARGE_SUCCESS });
		yield call(getAllChargesRequestSaga)
	} catch (error) {
		yield put({ type: types.PUT_CHARGE_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message?.toString(), type: 'error' } });
	}
}

export function* deleteChargeRequestSaga({ payload }) {
	try {
		yield call(deleteOfAdmin, `/charges/${payload.id}`);
		yield put({ type: types.DELETE_CHARGE_SUCCESS });
		yield call(getAllChargesRequestSaga)
	} catch (error) {
		yield put({ type: types.DELETE_CHARGE_ERROR, error });
		yield put({ type: ACTIVATE_ALERT, payload: { message: error.response.data.message?.toString(), type: 'error' } });
	}
}