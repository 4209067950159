import httpInstance from '../../helpers/http.service';
const { VITE_APP_API } = import.meta.env;

export const postOfClient = (url, data, headers) => {
	return httpInstance()
		.post(VITE_APP_API + url, data, headers)
		.then(response => {
			return response.data;
		});
};

export const putOfClient = (url, data, headers) => {
	return httpInstance()
		.put(VITE_APP_API + url, data, headers)
		.then(response => {
			return response.data;
		});
};

export const patchOfClient = (url, data, headers) => {
	return httpInstance()
		.patch(VITE_APP_API + url, data, headers)
		.then(response => {
			return response.data;
		});
};

export const getOfClient = (url, headers) => {
	return httpInstance()
		.get(VITE_APP_API + url, headers)
		.then(response => {
			return response.data;
		});
};
