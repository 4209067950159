import { useContext } from 'react';

import { IconButton, useTheme } from '@mui/material';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
// import SettingsBrightnessIcon from '@mui/icons-material/SettingsBrightness';
import ColorModeContext from '../../core/ColorModeContext';

const ThemeChanger = () => {
	const theme = useTheme();
	const colorMode = useContext(ColorModeContext);

	const handleChange = () => {
		// theme.palette.mode === colorMode.mode;
		if (theme.palette.mode === 'light') {
			localStorage.setItem('Theme', 'dark');
			document.body.classList.remove('light-mode');
			document.body.classList.add('dark-mode');
		} else {
			localStorage.setItem('Theme', 'light');
			document.body.classList.remove('dark-mode');
			document.body.classList.add('light-mode');
		}

		colorMode.toggleColorMode();
	};

	return (
		<>
			<IconButton sx={{ ml: 1 }} onClick={handleChange} color='inherit' aria-label='theme'>
				{theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
			</IconButton>
		</>
	);
};

export default ThemeChanger;
