import { BrowserRouter as Router } from 'react-router-dom';
import { useEffect, useState, useMemo } from 'react';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import { Box } from '@mui/system';
// import { useMediaQuery } from '@mui/material';

import ColorModeContext from '../core/ColorModeContext';

import MainLayout from './layout/MainLayout';
import Start from './pages/Start';

import { Provider as ProviderRollbar, ErrorBoundary } from '@rollbar/react';

const { VITE_APP_ROLLBAR_ACCESS_TOKEN } = import.meta.env;

const rollbarConfig = {
	accessToken: VITE_APP_ROLLBAR_ACCESS_TOKEN,
	captureUncaught: true,
	captureUnhandledRejections: true,
	payload: {
		environment: 'production',
		client: {
			javascript: {
				source_map_enabled: true, // false by default

				// -- Add this into your configuration ---
				// eslint-disable-next-line no-undef
				code_version: APP_VERSION,
				// ---------------------------------------

				// Optionally have Rollbar guess which frames the error was
				// thrown from when the browser does not provide line
				// and column numbers.
				guess_uncaught_frames: true,
			},
		},
	},
};

const App = ({ store, ...props }) => {
	const [mode, setMode] = useState('light');
	// media query para que se adapte al tema del navegador
	// const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

	useEffect(() => {
		const getTheme = localStorage.getItem('Theme');

		if (getTheme === 'dark') {
			document.body.classList.remove('light-mode');
			document.body.classList.add('dark-mode');
			setMode('dark');
		}

		// if (prefersDarkMode) {
		// 	document.body.classList.remove('light-mode');
		// 	document.body.classList.add('dark-mode');
		// 	setMode('dark');
		// } else {
		// 	document.body.classList.remove('dark-mode');
		// 	document.body.classList.add('light-mode');
		// 	setMode('light');
		// }
	}, []);

	const colorMode = useMemo(
		() => ({
			// mode,
			toggleColorMode: () => {
				setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'));
			},
		}),
		[]
	);

	const theme = useMemo(
		() =>
			createTheme({
				palette: {
					mode, // mode: prefersDarkMode ? 'dark' : 'light',
					primary: {
						// light: will be calculated from palette.primary.main,
						main: '#4486A1',
						// dark: will be calculated from palette.primary.main,
						// contrastText: will be calculated to contrast with palette.primary.main
					},
					secondary: {
						// light: will be calculated from palette.primary.main,
						main: '#ffde59',
						// dark: will be calculated from palette.secondary.main,
						// contrastText: will be calculated to contrast with palette.primary.main
					},
					neutral: {
						main: '#64748B',
						contrastText: '#ffffff',
					},
					// Used by `getContrastText()` to maximize the contrast between
					// the background and the text.
					contrastThreshold: 3,
					// Used by the functions below to shift a color's luminance by approximately
					// two indexes within its tonal palette.
					// E.g., shift from Red 500 to Red 300 or Red 700.
					tonalOffset: 0.2,
					...(mode === 'dark' && {
						background: {
							default: grey[900],
							paper: '#000',
						},
					}),
					text: {
						...(mode === 'light'
							? {
									primary: grey[900],
									secondary: grey[800],
							  }
							: {
									primary: '#fff',
									secondary: grey[300],
							  }),
					},
				},
				typography: {
					bodyBold: {
						fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
						fontSize: '1rem',
						fontWeight: 600, // or 'bold'
						lineHeight: 1.5,
						letterSpacing: '0.00938em',
					},
					subtitleBold: {
						fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
						fontSize: '1rem',
						fontWeight: 600, // or 'bold'
						lineHeight: 1.75,
						letterSpacing: '0.00938em',
					},
					fontWeightLight: 300,
					fontWeightRegular: 400,
					fontWeightMedium: 500,
					fontWeightBold: 700,
				},
			}),
		[mode]
	);

	// const darkModeTheme = useMemo(createTheme(getDesignTokens('dark')), []);

	return (
		<ProviderRollbar config={rollbarConfig}>
			<ErrorBoundary>
				<Provider store={store}>
					<Router>
						<ColorModeContext.Provider value={colorMode}>
							<ThemeProvider theme={theme}>
								<MainLayout {...props} />
								{/* Box para que no se tape con header */}
								<Box component='main' sx={{ flexGrow: 1, mt: 10, mr: 5, mb: 5 }}>
									<Start />
								</Box>
							</ThemeProvider>
						</ColorModeContext.Provider>
					</Router>
				</Provider>
			</ErrorBoundary>
		</ProviderRollbar>
	);
};

App.propTypes = {
	store: PropTypes.object.isRequired,
};

export default App;
