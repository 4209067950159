export const GET_DROPSHIPPING_ASSOCIATED_REQUEST = 'GET_DROPSHIPPING_ASSOCIATED_REQUEST';
export const GET_DROPSHIPPING_ASSOCIATED_SUCCESS = 'GET_DROPSHIPPING_ASSOCIATED_SUCCESS';
export const GET_DROPSHIPPING_ASSOCIATED_ERROR = 'GET_DROPSHIPPING_ASSOCIATED_ERROR';

export const PATCH_DROPSHIPPING_ASSOCIATED_REQUEST = 'PATCH_DROPSHIPPING_ASSOCIATED_REQUEST';
export const PATCH_DROPSHIPPING_ASSOCIATED_SUCCESS = 'PATCH_DROPSHIPPING_ASSOCIATED_SUCCESS';
export const PATCH_DROPSHIPPING_ASSOCIATED_ERROR = 'PATCH_DROPSHIPPING_ASSOCIATED_ERROR';

export const PUT_DROPSHIPPING_ASSOCIATED_REQUEST = 'PUT_DROPSHIPPING_ASSOCIATED_REQUEST';
export const PUT_DROPSHIPPING_ASSOCIATED_SUCCESS = 'PUT_DROPSHIPPING_ASSOCIATED_SUCCESS';
export const PUT_DROPSHIPPING_ASSOCIATED_ERROR = 'PUT_DROPSHIPPING_ASSOCIATED_ERROR';

export const GET_PURCHASE_ASSOCIATED_REQUEST = 'GET_PURCHASE_ASSOCIATED_REQUEST';
export const GET_PURCHASE_ASSOCIATED_SUCCESS = 'GET_PURCHASE_ASSOCIATED_SUCCESS';
export const GET_PURCHASE_ASSOCIATED_ERROR = 'GET_PURCHASE_ASSOCIATED_ERROR';

export const GET_PURCHASE_ID_ASSOCIATED_REQUEST = 'GET_PURCHASE_ID_ASSOCIATED_REQUEST';
export const GET_PURCHASE_ID_ASSOCIATED_SUCCESS = 'GET_PURCHASE_ID_ASSOCIATED_SUCCESS';
export const GET_PURCHASE_ID_ASSOCIATED_ERROR = 'GET_PURCHASE_ID_ASSOCIATED_ERROR';

export const PATCH_PURCHASE_ASSOCIATED_REQUEST = 'PATCH_PURCHASE_ASSOCIATED_REQUEST';
export const PATCH_PURCHASE_ASSOCIATED_SUCCESS = 'PATCH_PURCHASE_ASSOCIATED_SUCCESS';
export const PATCH_PURCHASE_ASSOCIATED_ERROR = 'PATCH_PURCHASE_ASSOCIATED_ERROR';

export const DELETE_ITEM_PURCHASE_ASSOCIATED_REQUEST = 'DELETE_ITEM_PURCHASE_ASSOCIATED_REQUEST';
export const DELETE_ITEM_PURCHASE_ASSOCIATED_SUCCESS = 'DELETE_ITEM_PURCHASE_ASSOCIATED_SUCCESS';
export const DELETE_ITEM_PURCHASE_ASSOCIATED_ERROR = 'DELETE_ITEM_PURCHASE_ASSOCIATED_ERROR';

export const POST_APPROVE_PURCHASE_ASSOCIATED_REQUEST = 'POST_APPROVE_PURCHASE_ASSOCIATED_REQUEST';
export const POST_APPROVE_PURCHASE_ASSOCIATED_SUCCESS = 'POST_APPROVE_PURCHASE_ASSOCIATED_SUCCESS';
export const POST_APPROVE_PURCHASE_ASSOCIATED_ERROR = 'POST_APPROVE_PURCHASE_ASSOCIATED_ERROR';

export const POST_REVIEW_PURCHASE_ASSOCIATED_REQUEST = 'POST_REVIEW_PURCHASE_ASSOCIATED_REQUEST';
export const POST_REVIEW_PURCHASE_ASSOCIATED_SUCCESS = 'POST_REVIEW_PURCHASE_ASSOCIATED_SUCCESS';
export const POST_REVIEW_PURCHASE_ASSOCIATED_ERROR = 'POST_REVIEW_PURCHASE_ASSOCIATED_ERROR';

export const POST_UPLOAD_BUNDLE_INVOICE_REQUEST = 'POST_UPLOAD_BUNDLE_INVOICE_ASSOCIATE_REQUEST';
export const POST_UPLOAD_BUNDLE_INVOICE_ERROR = 'POST_UPLOAD_BUNDLE_INVOICE_ASSOCIATE_ERROR';
export const POST_UPLOAD_BUNDLE_INVOICE_SUCCESS = 'POST_UPLOAD_BUNDLE_INVOICE_ASSOCIATE_SUCCESS';

export const PUT_BUNDLE_VALUE_REQUEST = 'PUT_BUNDLE_VALUE_ASSOCIATE_REQUEST';
export const PUT_BUNDLE_VALUE_ERROR = 'PUT_BUNDLE_VALUE_ASSOCIATE_ERROR';
export const PUT_BUNDLE_VALUE_SUCCESS = 'PUT_BUNDLE_VALUE_ASSOCIATE_SUCCESS';