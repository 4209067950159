import admin from './admin';
import associated from './associated';
import client from './client';
// import pages from './pages';
// import utilities from './utilities';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
	items: [client, admin, associated], //, pages, utilities, other]
};

export default menuItems;
