import { useEffect, useRef, useState } from 'react';
// material
import { alpha } from '@mui/material/styles';
import { Box, MenuItem, ListItemIcon, ListItemText, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
// components
import MenuPopover from './MenuPopover';
import EnglishLogo from '../../../assets/img/icons/ic_flag_en.svg';
import SpanishLogo from '../../../assets/img/icons/ic_flag_es.svg';

export default function LanguagePopover() {
	const anchorRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [selected, setSelected] = useState(0);
	const { t, i18n } = useTranslation();

	useEffect(() => {
		const lang = localStorage.getItem('Lang');
		setSelected(lang === 'en' ? 0 : 1);
	}, []);

	const LANGS = [
		{
			value: 'en',
			label: t('english'),
			icon: EnglishLogo,
		},
		{
			value: 'es',
			label: t('spanish'),
			icon: SpanishLogo,
		},
	];

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = item => {
		i18n.changeLanguage(item.value);
		localStorage.setItem('Lang', item.value);
		setOpen(false);
		setSelected(item.value === 'en' ? 0 : 1);
	};

	return (
		<>
			<IconButton
				ref={anchorRef}
				onClick={handleOpen}
				sx={{
					padding: 0,
					width: 44,
					height: 44,
					...(open && {
						bgcolor: theme => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
					}),
				}}
			>
				<img src={LANGS[selected].icon} alt={LANGS[selected].label} />
			</IconButton>

			<MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current}>
				<Box sx={{ py: 1 }}>
					{LANGS.map(option => (
						<MenuItem
							key={option.value}
							selected={option.value === LANGS[selected].value}
							onClick={() => handleClose(option)}
							sx={{ py: 1, px: 2.5 }}
						>
							<ListItemIcon>
								<Box component='img' alt={option.label} src={option.icon} />
							</ListItemIcon>
							<ListItemText primaryTypographyProps={{ variant: 'body2' }} primary={option.label} />
						</MenuItem>
					))}
				</Box>
			</MenuPopover>
		</>
	);
}
