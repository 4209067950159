import { combineReducers } from 'redux';
// import demoReducer from './demoReducer';
import authReducer from './authReducer';
import clientReducer from './clientReducer';
import adminReducer from './adminReducer';
import associatedReducer from './associatedReducer';
import utilsReducer from './utilsReducer';
import allUsersReducer from './allUsersReducer';
import chargesReducer from './admin/chargesReducer';

const rootReducer = combineReducers({
	authReducer,
	clientReducer,
	adminReducer,
	associatedReducer,
	utilsReducer,
	allUsersReducer,
	chargesReducer,
});

export default rootReducer;
